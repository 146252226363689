<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import * as moment from "moment";
import {
  FETCH_TRANSACTION_DETAILS,
  FETCH_WEBHOOK_DATA_FOR_TRANSACTION,
  FETCH_FAILOVER_DATA_FOR_TRANSACTION,
  RESEND_WEBHOOK_DATA_FOR_TRANSACTION,
  UPDATE_TRXN_STATUS
} from "@/state/actions.type";
import CloseModalBtn from '../../../components/close-modal-btn.vue';
import Breadcrumb from '../../../components/breadcrumb.vue';

export default {
  components: {
    Layout,
    // PageHeader,
    CloseModalBtn,
    Breadcrumb
  },
  data() {
    return {
      orderRef: "",
      webhookDetailsTableFields: [
        { key: "request_time", label: 'views.payments.transactions.table.request_time' },
        { key: "webhook_id", label: 'views.payments.transactions.table.webhook_id' },
        { key: "request_url", label: 'views.payments.transactions.table.request_url' },
        { key: "webhook_status", label: 'views.payments.transactions.table.webhook_status' },
        { key: "response_code", label: 'views.payments.transactions.table.response_code' },
        { key: "response_body", label: 'views.payments.transactions.table.response_body' },
        { key: "request_body", label: 'views.payments.transactions.table.request_body' },
      ],
      failoverTransactionTableFields: [
        { key: "order_ref", label: 'PortOne Txn Ref' },
        // { key: "show_details", label: 'Options'},
        { key: "channel_key", label: 'PSP' },
        { key: "method_key", label: 'Payment Method' },
        { key: "rank", label: 'Rank' },
        { key: "status", label: 'Status' },
        { key: "status_reason", label: 'Status Reason' },
        { key: "status_channel_reason", label: 'Status Channel Reason' }
      ],
      channelEventTableFields: [
        { key: "created_at", label: 'views.payments.transactions.table.created_at' },
        { key: "response", label: 'views.payments.transactions.table.response' },
      ],
      slide: 0,
      showCollapse: {
        txnDetails: true,
        additionalDetails: true,
        shopperDetails: true,
        webhookDetails: true,
        routedTxnDetails: true,
        callbackEventDetails: true
      }
    };
  },

  created() {
    this.transactionDetailsFetch();
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("common.transactions"),
          to: { name: 'Transactions' }
        },
        {
          text: this.$t("views.payments.transactions.title"),
          active: true
        }
      ];
    },

    title() {
      return this.$t("views.payments.transactions.title");
    },

    webhookDataForSelectedTransaction() {
      return this.$store.state.transactions.webhookDetailsForSelectedTransaction.webhookData;
    },

    failoverTransactions() {
      return this.$store.state.transactions.failoverTrasactions;
    },

    channelEventsForSelectedTransaction() {
      return this.$store.state.transactions.webhookDetailsForSelectedTransaction.channelEvents;
    },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },

    transactionData() {
      return this.$store.state.transactions.transactionData;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
    address() {
      let add1 = this.transactionData.buyer_address1 ? `${this.transactionData.buyer_address1}, ` : "";
      let city = this.transactionData.buyer_city ? `${this.transactionData.buyer_city}, ` : "";
      return add1 + city + this.transactionData.buyer_country;
    }
  },

  watch: {
    '$route.query.order_ref'() {
      this.transactionDetailsFetch();
    }
  },

  filters: {
    date(date) {
      return moment(date).format("MMM Do YYYY, h:mm:ss a");
    },
    dateFormat(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
      return 'at ' + moment(value).format("hh:mm A");
    },
  },

  methods: {
    transactionDetailsFetch() {
      this.orderRef = this.$route.query.order_ref;
      this.$store.dispatch(`transactions/${FETCH_TRANSACTION_DETAILS}`, {
        order_ref: this.orderRef,
        submerchant_key: this.$route.query.key
      }).then(() => {
        if(this.$route.query.view_payslips === 'true') {
          if(this.transactionData.channel_key === 'OTHERS' && this.transactionData.method_key === 'OTHERS_DIRECT_BANK_TRANSFER' && this.transactionData.direct_bank_transfer_details && this.transactionData.direct_bank_transfer_details.payment_slip && !this.isMasterMerchant) {
            this.$bvModal.show('modal-payslip');
          }
        }
        this.fetchTransactionWebhookDetails(this.transactionData);
        if(this.transactionData.routing_enabled) {
          this.fetchFailoverTransactions(this.transactionData);
        }
      });
    },
    fetchFailoverTransactions(txnDetails) {
      this.$store.dispatch(`transactions/${FETCH_FAILOVER_DATA_FOR_TRANSACTION}`, {
        merchant_order_ref: txnDetails.merchant_order_ref,
        submerchant_key: txnDetails.merchant_key
      }).catch(error => {
        console.log(error);
        const errorMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errorMsg.message,
          closeOnClick: true
        });
      });
    },
    fetchTransactionWebhookDetails(txnDetails) {
      this.$store.dispatch(`transactions/${FETCH_WEBHOOK_DATA_FOR_TRANSACTION}`, {
        order_ref: txnDetails.order_ref,
        submerchant_key: txnDetails.merchant_key
      }).catch(error => {
        console.log(error);
        const errorMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errorMsg.message,
          closeOnClick: true
        });
      });
    },
    transactionAmount() {
      if(this.transactionData.country_code) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.transactionData.currency }).format(this.transactionData.amount)
      }
      return this.transactionData.amount;
    },

    transactionStatusVariant() {
      let transactionStatus;
      switch (this.transactionData.status) {
        case 'Success':
          transactionStatus = 'success';
          break;
        case 'Initiated':
          transactionStatus = 'info';
          break;
        case 'Failed':
          transactionStatus = 'danger';
          break;
        case 'Expired':
          transactionStatus = 'secondary';
          break;
        case 'Voided':
          transactionStatus = 'danger';
          break;
        case 'Under Review':
          transactionStatus = 'orange';
          break;
        default:
          transactionStatus = 'secondary';
          break;
      }
      return transactionStatus;
    },

    webhookReload() {
      this.$store.dispatch(`transactions/${RESEND_WEBHOOK_DATA_FOR_TRANSACTION}`, this.orderRef)
    },

    openRefundsDetailsModal() {
      this.$router.push({path: '/transactions/payments/payment-details/refund', query: { order_ref: this.orderRef }});
    },

    hideUpdateStatusModal() {
      this.$refs['modal-update-status'].hide()
    },

    approvePayslip(status) {
      this.$store.dispatch(`transactions/${UPDATE_TRXN_STATUS}`, {
        status: status,
        merchantOrderRef: this.transactionData.merchant_order_ref
      })
      this.slide = 0;
    },
    fileType(file) {
      return file.substring(file.lastIndexOf('.'))
    },
    pdfUrl(link) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {
          return `https://docs.google.com/gview?url=${link}&embedded=true`;
        } else {
          return link;
        }
      }
      return `https://docs.google.com/gview?url=${link}&embedded=true`;
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "Copied Successfully",
        closeOnClick: true,
      });
    },
    openTransactionDetails(order_ref) {
      this.$router.push(`/transactions/payments/payment-details?order_ref=${order_ref}&key=${this.isMasterMerchant ? this.transactionData.merchant_key : ''}`);
    },
    onSlideNext() {
      if(this.slide < this.transactionData.direct_bank_transfer_details.payment_slip.length - 1) {
        this.slide++;
      }
    },
    onSlidePrev() {
      if(this.slide > 0) {
        this.slide--;
      }
    },
    downloadPayslip(payslip) {
      if(!payslip) {
        payslip = this.transactionData.direct_bank_transfer_details.payment_slip[this.slide];
      }
      window.open(payslip, '_blank');
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="d-flex" style="height: 44px;">
      <breadcrumb :items="items" :goBackTo="{ name: 'Transactions' }" />
      <div class="ml-auto">
        <b-btn
          variant="white"
          v-b-modal.modal-update-status
          v-if="((transactionData.status === 'Error' && transactionData.channel_name === 'CYBERSOURCE') || (transactionData.status === 'Processing' && transactionData.channel_name === 'VTCPAY') || transactionData.status === 'Under Review') && !isMasterMerchant"
          style="padding: 20px 25px;"
        >
          Update Status
        </b-btn>
        <b-btn
          variant="white"
          v-b-modal.modal-payslip
          v-if="transactionData.channel_key === 'OTHERS' && transactionData.method_key === 'OTHERS_DIRECT_BANK_TRANSFER' && transactionData.direct_bank_transfer_details && transactionData.direct_bank_transfer_details.payment_slip && !isMasterMerchant"
          style="padding: 20px 25px;"
        >
          View Payslip
        </b-btn>
        <b-btn
          variant="primary"
          v-if="userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-refund-user') || userRoles.includes('payment-admin')"
          :disabled="!['Success', 'Authorized'].includes(transactionData.status) || transactionData.is_refund_allowed === false"
          @click="openRefundsDetailsModal"
          style="padding: 20px 25px;"
        ><img src="@/assets/images/refund.svg" alt="refund" class="mr-1" />{{ $t("views.payments.transactions.btn-refund") }}</b-btn>
      </div>
    </div>

    <!-- Transaction Details -->
    <div class="my-4">
      <div v-b-toggle.collapse-transaction-details class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          Transaction Details
        </h3>
        <img v-show="showCollapse.txnDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.txnDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.txnDetails" id="collapse-transaction-details" class="mt-2">
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction ID</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.merchant_order_ref }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction Date</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.created_at | date }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Customer Name</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.buyer_name || '-' }}<span v-if="transactionData.buyer_email">( <a class="text-chai font-16px" :href="`mailto:${transactionData.buyer_email}`">{{ transactionData.buyer_email }}</a> )</span></p>
        </div>
        <div v-if="transactionData.cross_border_data && 'forex_details' in transactionData.cross_border_data">
          <div class="row collapse-body-table-row">
            <p class="col-6 collapse-body-table-head-col">Amount</p>
            <p class="col-6 collapse-body-table-col">{{ transactionData.cross_border_data.forex_details.invoice_amount }}</p>
          </div>
          <div class="row collapse-body-table-row">
            <p class="col-6 collapse-body-table-head-col">Currency</p>
            <p class="col-6 collapse-body-table-col">{{ transactionData.cross_border_data.forex_details.invoice_currency }}</p>
          </div>
          <div class="row collapse-body-table-row">
            <p class="col-6 collapse-body-table-head-col">Forex Rate</p>
            <p class="col-6 collapse-body-table-col">{{ transactionData.cross_border_data.forex_details.forex_rate }}</p>
          </div>
          <div class="row collapse-body-table-row">
            <p class="col-6 collapse-body-table-head-col">Payment Amount</p>
            <p class="col-6 collapse-body-table-col">{{ transactionData.cross_border_data.forex_details.payment_amount }}</p>
          </div>
          <div class="row collapse-body-table-row">
            <p class="col-6 collapse-body-table-head-col">Payment Currency</p>
            <p class="col-6 collapse-body-table-col">{{ transactionData.cross_border_data.forex_details.payment_currency }}</p>
          </div>
        </div>
        <div v-else class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Amount</p>
          <p class="col-6 collapse-body-table-col">{{ transactionAmount() }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Method</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.method_name }}</p>
        </div>
        <div class="row collapse-body-table-row" v-if="transactionData.masked_card_number">
          <p class="col-6 collapse-body-table-head-col">Masked CardNumber</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.masked_card_number }}</p>
        </div>
        
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Channel</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.channel_name }}</p>
        </div>
        <div class="row collapse-body-table-row" v-if="transactionData.payment_link_ref">
          <p class="col-6 collapse-body-table-head-col">Payment Link Ref</p>
          <p class="col-6 collapse-body-table-col">
            <router-link class="text-chai font-16px" :to="{ name: 'Payment Link Details', query: { pageRef: transactionData.payment_link_ref } }">
              {{ transactionData.payment_link_ref }}
            </router-link>
          </p>
        </div>
        <div class="row collapse-body-table-row" v-if="transactionData.payment_page_ref">
          <p class="col-6 collapse-body-table-head-col">Payment page Ref</p>
          <p class="col-6 collapse-body-table-col">
            <router-link class="text-chai font-16px" :to="{ name: 'Payment Page Details', query: { pageRef: transactionData.payment_page_ref } }">
              {{ transactionData.payment_page_ref }}
            </router-link>
          </p>
        </div>
        <div class="row collapse-body-table-row" v-if="transactionData.subscription_order_ref">
          <p class="col-6 collapse-body-table-head-col">Subscription Ref</p>
          <p class="col-6 collapse-body-table-col">
            <router-link class="text-chai font-16px" :to="{ name: 'Subscription Details', query: { pageRef: transactionData.subscription_order_ref } }">
              {{ transactionData.subscription_order_ref }}
            </router-link>
          </p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Merchant Email</p>
          <p class="col-6 collapse-body-table-col"><a v-if="transactionData.merchant_email_address" class="text-chai font-16px" :href="`mailto:${transactionData.merchant_email_address}`">{{ transactionData.merchant_email_address }}</a></p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">PortOne Reference ID</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.order_ref }}</p>
        </div>
  
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction Status</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.status }}</p>
        </div>

        <div class="row collapse-body-table-row" v-if="transactionData.receipt_url">
          <p class="col-6 collapse-body-table-head-col">Receipt Url</p>
          <p class="col-6 collapse-body-table-col d-flex" style="gap: 20px;">
              <a v-if="transactionData.receipt_url" class="text-chai text-truncate d-inline-block my-auto" target="_blank" :href="transactionData.receipt_url" style="width: 80%;">
                {{ transactionData.receipt_url }}
              </a>
              <i
                v-show="transactionData.receipt_url"
                v-clipboard:copy="transactionData.receipt_url"
                v-clipboard:success="onCopySuccess"
                v-b-tooltip.hover.right
                :title="$t('common.copy')"
                class="mdi mdi-content-copy btn p-0 my-auto"
              ></i>
          </p>
        </div>
      </b-collapse>
    </div>

    <!-- Additional Details -->
    <div class="my-4">
      <div v-b-toggle.collapse-additional-details class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          Additional Details
        </h3>
        <img v-show="showCollapse.additionalDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.additionalDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.additionalDetails" id="collapse-additional-details" class="mt-2">
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Service Provider</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.channel_name }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction Status Code</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.status_code || '-' }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Actual Payment Method</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.method_name }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Attempt</p>
          <p class="col-6 collapse-body-table-col"></p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">IP Address</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.ip_address }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Device Info</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.user_agent }}</p>
        </div>
      </b-collapse>
    </div>

    <!-- Shopper Details -->
    <div class="my-4">
      <div v-b-toggle.collapse-shopper-details class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          Shopper Details
        </h3>
        <img v-show="showCollapse.shopperDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.shopperDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.shopperDetails" id="collapse-shopper-details" class="mt-2">
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Phone Number</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.buyer_phone }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Address</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.buyer_address1 ? `${transactionData.buyer_address1}, ${transactionData.buyer_address2}, ${transactionData.buyer_city}, ${transactionData.buyer_country}` : '-' }}</p>
        </div>
      </b-collapse>
    </div>

    <!-- Webhook Details -->
    <div class="my-4">
      <div v-b-toggle.collapse-webhook-details class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          Webhook Details
        </h3>
        <img v-show="showCollapse.webhookDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.webhookDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.webhookDetails" id="collapse-webhook-details">
        <div class="d-flex justify-content-between m-3">
          <p class="my-auto collapse-body-table-head-col">Trace Logs And Additional Details For Webhook Sent From PortOne Server For The Transaction.</p>
          <b-btn variant="primary" size="sm" @click="webhookReload">{{ $t("views.payments.transactions.resend_webhook") }}</b-btn>
        </div>
        <b-table
          class="table-custom text-center table-centered m-3"
          responsive="sm"
          :items="webhookDataForSelectedTransaction"
          :fields="webhookDetailsTableFields"
          :small="true"
          sticky-header="60vh"
          show-empty
          :empty-text="$t('common.no-records')"
          hover
        >
          <template v-slot:head()="data">
            {{ $t(data.label) }}
          </template>
          <template v-slot:cell(request_time)="row">
            <p class="table-date">{{ row.value | dateFormat }}</p>
            <p class="table-time">{{ row.value | time }}</p>
          </template>
        </b-table>
      </b-collapse>
    </div>

    <!-- Routed Transactions -->
    <div class="my-4">
      <div v-b-toggle.collapse-routed-transactions class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          Routed Transactions
        </h3>
        <img v-show="showCollapse.routedTxnDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.routedTxnDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.routedTxnDetails" id="collapse-routed-transactions">
        <b-table
          class="table-custom text-center table-centered m-3"
          :items="failoverTransactions"
          :fields="failoverTransactionTableFields"
          :small="true"
          sticky-header="60vh"
          show-empty
          :empty-text="$t('common.no-records')"
          hover
        >
          <template #cell(order_ref)="row">
            <b-btn v-if="row.item.details_available" variant="link" class="text-chai font-16px" @click="openTransactionDetails(row.item.order_ref)">{{row.value}}</b-btn>
            <span v-else>{{row.value}}</span>
          </template>
        </b-table>
      </b-collapse>
    </div>


    <!-- Callback Event Details -->
    <div class="my-4">
      <div v-b-toggle.collapse-callback-event-details class="d-flex justify-content-between p-3 custom-collapse common-bg-30">
        <h3 class="mb-0 collapse-header">
          {{ $t("views.payments.transactions.cb_event_details") }}
        </h3>
        <img v-show="showCollapse.callbackEventDetails" src="@/assets/images/drop_up.svg" alt="down"/>
        <img v-show="!showCollapse.callbackEventDetails" src="@/assets/images/drop_down.svg" alt="down"/>
      </div>
      <b-collapse v-model="showCollapse.callbackEventDetails" id="collapse-callback-event-details">
        <p class="m-3 collapse-body-table-head-col">Trace Logs And Additional Details For Webhook Sent From Payment Channel's Server To PortOne Server For The Transaction.</p>
        <b-table
          class="table-custom text-center table-centered m-3"
          responsive="sm"
          :items="channelEventsForSelectedTransaction"
          :fields="channelEventTableFields"
          :small="true"
          sticky-header="500px"
          show-empty
          :empty-text="$t('common.no-records')"
          hover
        >
          <template v-slot:head()="data">
            {{ $t(data.label) }}
          </template>
          <template v-slot:cell(created_at)="row">
            <p class="table-date">{{ row.value | dateFormat }}</p>
            <p class="table-time">{{ row.value | time }}</p>
          </template>
        </b-table>
      </b-collapse>
    </div>

    <b-modal
      ref="modal-payslip"
      id="modal-payslip"
      ok-title="Approve"
      title="View Payslip"
      cancel-title="Reject"
      ok-variant="success-approve px-3"
      cancel-variant="primary px-3"
      footer-bg-variant="light"
      footer-class="justify-content-center"
      size="lg"
      @ok="approvePayslip(true)"
      @hidden="() => slide = 0"
      @cancel="approvePayslip(false)"
      :hide-footer="['Success', 'Failed'].includes(this.transactionData.status)"
      no-close-on-backdrop
      v-if="transactionData.channel_key === 'OTHERS' && transactionData.method_key === 'OTHERS_DIRECT_BANK_TRANSFER' && transactionData.direct_bank_transfer_details && transactionData.direct_bank_transfer_details.payment_slip && !isMasterMerchant"
    >
      <div class="d-flex justify-content-center w-100" style="height: 75vh; overflow: hidden;">
        <div class="w-100">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <span class="h5 my-auto">{{ slide + 1 + " / " + transactionData.direct_bank_transfer_details.payment_slip.length }}</span>
            </div>
            <b-button-group>
              <b-btn variant="light" @click="onSlidePrev" :disabled="slide < 1"><i class="mdi mdi-arrow-collapse-left"></i></b-btn>
              <b-btn variant="light" @click="downloadPayslip()" class="mx-2"><i class="mdi mdi-download"></i></b-btn>
              <b-btn variant="light" @click="onSlideNext" :disabled="slide >= transactionData.direct_bank_transfer_details.payment_slip.length - 1"><i class="mdi mdi-arrow-collapse-right"></i></b-btn>
            </b-button-group>
          </div>

          <div class="border w-100 mt-3" style="height: 80%;" v-show="index === slide" v-for="(payslip, index) in transactionData.direct_bank_transfer_details.payment_slip" :key="index">
            <div class="d-flex h-100 justify-content-center">
              <embed :src="pdfUrl(payslip)" v-if="fileType(payslip) == '.pdf'" type="application/pdf" width="100%" height="600px" />
              <img v-else :src="payslip" class="img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modal-update-status"
      id="modal-update-status"
      ok-title="Success"
      cancel-title="Failed"
      ok-variant="success-approve px-3"
      cancel-variant="primary px-3"
      footer-bg-variant="light"
      footer-class="justify-content-center"
      size="md"
      @ok="approvePayslip(true)"
      @cancel="approvePayslip(false)"
      no-close-on-backdrop
      hide-header
      centered
      v-if="((transactionData.status === 'Error' && transactionData.channel_name === 'CYBERSOURCE') || (transactionData.status === 'Processing' && transactionData.channel_name === 'VTCPAY') || transactionData.status === 'Under Review') && !isMasterMerchant"
    >
      <span @click="hideUpdateStatusModal"><CloseModalBtn /></span>
      <div class="d-flex justify-content-center mx-auto my-3 text-danger">
        Please use the respective PSP Dashboard to verify and update the correct status of this transaction.
      </div>
    </b-modal>

    <!-- <b-alert class="my-3 mx-1 row text-reset font-weight-bold" show>
      <span class="col-md-8 d-flex"><p class="my-auto">{{ $t("views.payments.transactions.order_reference") }} - {{ transactionData.merchant_order_ref }}</p></span>
      <h5 class="mb-0 col-md-4 d-flex justify-content-between">
        <span class="my-auto">{{ transactionAmount() }}</span>
        <b-badge class="float-right" :variant="transactionStatusVariant()"><span class="h5" :class="transactionStatusVariant() === 'orange' ? 'badge-orange' : 'text-white'">{{ transactionData.status }}</span></b-badge>
      </h5>
    </b-alert> -->

    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">{{ $t("views.payments.transactions.transaction-details") }}</p>
      <div class="p-3">
        <table class="w-100">
          <colgroup>
            <col width="30%"/>
          </colgroup>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.order_reference") }}</td>
            <td>{{ transactionData.order_ref }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.psp_order_reference") }}</td>
            <td>{{ transactionData.channel_order_ref || `-` }}</td>
          </tr>
          <tr v-if="transactionData.payment_link_ref">
            <td class="text-muted">Payment Link Reference</td>
            <td>
              <router-link :to="`/payment-links-details?pageRef=${transactionData.payment_link_ref}`">
                {{ transactionData.payment_link_ref }}
              </router-link>
            </td>
          </tr>
          <tr v-if="transactionData.payment_page_ref">
            <td class="text-muted">Payment Page Reference</td>
            <td>
              <router-link :to="`/settings/payment-pages-details?pageRef=${transactionData.payment_page_ref}`">
                {{ transactionData.payment_page_ref }}
              </router-link>
            </td>
          </tr>
          <tr v-if="transactionData.subscription_order_ref">
            <td class="text-muted">Subscription Link Reference</td>
            <td>
              <router-link :to="`/subscription-details?pageRef=${transactionData.subscription_order_ref}`">
                {{ transactionData.subscription_order_ref }}
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.date") }}</td>
            <td>{{ transactionData.date | date }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.currency") }}</td>
            <td>{{ transactionData.currency }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("common.amount") }}</td>
            <td>{{ transactionData.amount }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.payment_status") }}</td>
            <td>{{ transactionData.status }}</td>
          </tr>
        </table>
      </div>
    </div> -->
    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">{{ $t("views.payments.transactions.additional_info") }}</p>
      <div class="p-3">
        <table class="w-100">
          <colgroup>
            <col width="30%"/>
          </colgroup>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.payment_service_provider") }}</td>
            <td>{{ transactionData.channel_name }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.payment_method") }}</td>
            <td>{{ transactionData.method_name }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.status_code") }}</td>
            <td>{{ transactionData.status_code || '-' }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.status_reason") }}</td>
            <td>{{ transactionData.status_reason || '-' }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.status_psp_reason") }}</td>
            <td>{{ transactionData.status_channel_reason || '-' }}</td>
          </tr>
          <tr v-if="transactionData.routing_enabled && transactionData.route_rank">
            <td class="text-muted">Route Rank</td>
            <td>{{ transactionData.route_rank }}</td>
          </tr>
        </table>
      </div>
    </div> -->
    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">{{ $t("views.payments.transactions.shopper_details") }}</p>
      <div class="p-3">
        <table class="w-100">
          <colgroup>
            <col width="30%"/>
          </colgroup>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.name") }}</td>
            <td>{{ transactionData.buyer_name }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.email") }}</td>
            <td>{{ transactionData.buyer_email }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.phone_number") }}</td>
            <td>{{ transactionData.buyer_phone }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.address") }}</td>
            <td>{{ address }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.ip_address") }}</td>
            <td>{{ transactionData.ip_address }}</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t("views.payments.transactions.table.device_info") }}</td>
            <td>{{ transactionData.user_agent }}</td>
          </tr>
        </table>
      </div>
    </div> -->
    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">{{ $t("views.payments.transactions.webhook_details") }}</p>
      <div class="p-3">
        <div class="d-flex justify-content-between">
          <p>{{ $t("views.payments.transactions.trace_log_title") }}</p>
          <div><b-btn variant="primary" size="sm" @click="webhookReload">{{ $t("views.payments.transactions.resend_webhook") }}</b-btn></div>
        </div>
        <div class="table-responsive text-center table-custom" style="padding-top:1rem;">
          <b-table
            class="table-centered"
            responsive="sm"
            :items="webhookDataForSelectedTransaction"
            :fields="webhookDetailsTableFields"
            :bordered="true"
            :small="true"
            sticky-header="60vh"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              {{ $t(data.label) }}
            </template>
          </b-table>
        </div>
      </div>
    </div> -->
    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">Routed Transactions</p>
      <div class="p-3">
        <div class="table-responsive text-center table-custom" style="padding-top:1rem;">
          <b-table
            class="table-centered"
            responsive="sm"
            :items="failoverTransactions"
            :fields="failoverTransactionTableFields"
            :bordered="true"
            :small="true"
            sticky-header="60vh"
            show-empty
            :empty-text="$t('views.payments.transactions.no-records')"
            busy.sync="true"
            hover
          >
            <template #cell(order_ref)="row">
              <b-btn v-if="row.item.details_available" variant="link" class="text-chai font-16px" @click="openTransactionDetails(row.item.order_ref)">{{row.value}}</b-btn>
              <span v-else>{{row.value}}</span>
            </template>
          </b-table>
        </div>
      </div>
    </div> -->
    <!-- <div class="bg-white my-4 border rounded">
      <p class="bg-light px-3 py-2 mb-0">{{ $t("views.payments.transactions.cb_event_details") }}</p>
      <div class="p-3">
        <p>{{ $t("views.payments.transactions.trace_log_psp") }}</p>
        <div class="table-responsive text-center table-custom" style="padding-top:1rem;">
          <b-table
            class="table-centered"
            responsive="sm"
            :items="channelEventsForSelectedTransaction"
            :fields="channelEventTableFields"
            :bordered="true"
            :small="true"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              {{ $t(data.label) }}
            </template>
          </b-table>
        </div>
      </div>
    </div> -->
  </Layout>
</template>

<style lang="css" scoped>
.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}
.custom-collapse {
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid #DCDAD5;
}
.collapse-header {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
}
.collapse-body-table-row {
  border-bottom: 1px solid #DCDAD5;
  margin-right: unset;
  margin-left: unset;
  height: 60px;
}
.collapse-body-table-head-col {
  color: rgba(0, 0, 0, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}

.collapse-body-table-col {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}
</style>