<script>
import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import CheckboxBtn from "@/components/checkbox-btn";
import toggleBtn from "@/components/toggle-btn";
// import { required } from "vuelidate/lib/validators";
import OnboardingForm from "@/components/onboardingForm";
import { paymentMethods } from "@/state/helpers";
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS,
  UPDATE_PAYMENT_METHOD_CREDENTIALS,
  FETCH_PAYMENT_METHODS_CREDENTIALS,
  FETCH_PAYMENT_METHODS_CREDENTIALS_COUNT,
  ADD_PAYMENT_METHOD_CREDENTIALS,
  FETCH_ALL_PAYMENT_CHANNEL,
  UPDATE_ENABLE_AND_DEFAULT_STATUS,
  UPDATE_INSTALMENT_TYPE_DATA,
  ADD_PAYMENT_METHOD_IMPORT_CREDENTIALS,
  FETCH_PREVIEW_IAMPORT_CREDENTIALS
} from "@/state/actions.type";
import {
  UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE,
  UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE
} from '@/state/mutations.type';
import Multiselect from 'vue-multiselect';
import Swal from "sweetalert2";

const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export default {
  components: {
    Layout,
    // PageHeader,
    Multiselect,
    OnboardingForm,
    CheckboxBtn,
    toggleBtn
  },

  data() {
    var queryParams = new URLSearchParams(window.location.search)
    var channelName = queryParams.get("channelName")
    return {
      channelName:{channelName},
      subMerchantUUid:"",
      emailAddress:"",
      paymentChannelCreds: null,
      currentChannel: null,
      currentChannelCopy: null,
      addChannelCred: null,
      addChannelCredCopy: null,
      showPayInmodal: false,
      inViewMode:true,
      showAddCredPayInmodal: false,
      showAddOnboardingInmodal: false,
      isdata: false,
      formvalue: 0,
      formdata: {},
      paymentsMethodsData:[],
      selected_country:"",
      status:"",
      selected_country_for_add_cred:"",
      methosDetailsCoun:{},
      selected: [{ value: 'Thailand' }],
      installmentTypeData: [],
      subMerchantIamport : "",
      kyc_url : process.env.VUE_APP_PORTONE_KYC_URL,
      showextracontent:false,
      iamportextracreds:{},
      iamportcreds:{},
      iamportcredscopy:{},
      expandIamportUpdateForm:false,
      iamportextraupdatecreds:{},
      iamportupdatecreds:{},
      iamportupdatecredsjson:{},
      updatedCredsLoaded:false,
      showCollapse: {
        wallet: false,
        bnpl:false,
        netBanking:false,
        qrCode:false,
        installment:false
      }
      
    };
  },

  computed: {
    isValid() {
      if (this.currentChannel) {
        const creds = this.currentChannel.creds;
        for (let cred in creds) {
          if(this.currentChannel.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat', 'mid-Cards'].includes(cred)) {
            return true;
          }
          if (!String(creds[cred])) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    isValidAddCred() {
      if (this.addChannelCred) {
        const creds = this.addChannelCred.creds;
        for (let cred in creds) {
          if (!creds[cred]) {
            if(['areOtherCurrenciesSupported', 'shouldAuthenticate'].includes(cred)) {
              creds[cred] = 'false';
            } else if(this.topBarContData.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat', 'mid-Cards'].includes(cred)) {
              return true;
            } else {
              return false;
            }
          }
        }
        return true;
      } else {
        return false;
      }
    },
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.selectedChannelCreds;
    },
    methosDetailsCountData() {
      return this.$store.state.paymentChannels.methosDetailsCount;
    },
    paymentmethodCredsList() {
      return this.$store.state.paymentChannels.selectedChannelMethods;
    },
    iamportextraCreds(){
      return this.$store.state.paymentChannels.importCreds;
    },
    iamportextraUpdateCreds(){
      return this.$store.state.paymentChannels.iamportUpdateCreds;
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },


    countryList() {
      return this.$store.getters["paymentChannels/listOfCountry"];
    },
    countryListForAddCred() {
      return this.$store.getters["paymentChannels/listOfCountryForAddCred"];
    },
    topBarContData() {
      return this.$store.getters["paymentChannels/topBarData"];
    },
    dataForWalletType() {
      return this.$store.getters["paymentChannels/dataForWalletType"];
    },
    dataForPayByBankType() {
      return this.$store.getters["paymentChannels/dataForPayByBankType"];
    },
    dataForBnplType() {
      return this.$store.getters["paymentChannels/dataForBnplType"];
    },
    dataForEMIType() {
      return this.$store.getters["paymentChannels/dataForEMIType"];
    },
    dataForUPIType() {
      return this.$store.getters["paymentChannels/dataForUPIType"];
    },
    dataForNetBankingType() {
      return this.$store.getters["paymentChannels/dataForNetBankingType"];
    },
    dataForOTCType(){
      return this.$store.getters["paymentChannels/dataForOTCType"];
    },
    dataForQRCodeType() {
      return this.$store.getters["paymentChannels/dataForQRCodeType"];
    },
    dataForCryptoType() {
      return this.$store.getters["paymentChannels/dataForCryptoType"];
    },
    dataForBankTransferType() {
      return this.$store.getters["paymentChannels/dataForBankTransferType"];
    },
    dataForCodType() {
      return this.$store.getters["paymentChannels/dataForCodType"];
    },

    dataForDirectBankTransferType() {
      return this.$store.getters["paymentChannels/dataForDirectBankTransferType"];
    },

    dataForAllType() {
      return this.$store.getters["paymentChannels/dataForAllType"];
    },
    dataForCardType() {
      return this.$store.getters["paymentChannels/dataForCardType"];
    },
    dataForSmartButtonType() {
      return this.$store.getters["paymentChannels/dataForSmartButtonType"];
    },
    dataForCreditDebitCardType() {
      return this.$store.getters["paymentChannels/dataForCreditDebitCardType"];
    },
    dataForUnionPayCardType() {
      return this.$store.getters["paymentChannels/dataForUnionPayCardType"];
    },
    dataForInstallmentType() {
      return this.$store.getters["paymentChannels/dataForInstallmentType"];
    },
    dataForAtmCardType() {
      return this.$store.getters["paymentChannels/dataForAtmCardType"];
    },
    dataForDebitCardType() {
      return this.$store.getters["paymentChannels/dataForDebitCardType"];
    },
    dataForVaBankTransferType() {
      return this.$store.getters["paymentChannels/dataForVaBankTransferType"];
    },
    dataForDirectDebitType() {
      return this.$store.getters["paymentChannels/dataForDirectDebitType"];
    },
    dataForSubscriptionType() {
      return this.$store.getters["paymentChannels/dataForSubscriptionType"];
    },
    dataForSubscriptionBankTransferType() {
      return this.$store.getters["paymentChannels/dataForSubscriptionBankTransferType"];
    },
    dataForSubscriptionDirectDebitType() {
      return this.$store.getters["paymentChannels/dataForSubscriptionDirectDebitType"];
    },
    selectedCountry() {
      return this.countryList[0];
    },
    // items() {
    //   var queryParams = new URLSearchParams(window.location.search);
    //   return [
    //     {
    //       text: this.$t("menuitems.integration.list.payconfig"),
    //       active: true
    //     },
    //     {
    //       text: this.$t("menuitems.integration.list.pay-channels"),
    //       href: '/settings/pay-channel'
    //     },
    //     {
    //       text: `${queryParams.get("channelName").toLowerCase() == 'paypal' ? 'PayPal' : queryParams.get("channelName")}`,
    //       active: true,
    //     },
    //   ];
    // },

    // title() {
    //   return this.$t("menuitems.integration.list.pay-channels");
    //   // return this.$t("menuitems.integration.list.pay-channelstwo");
    // },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
  },

  created() {
    var queryParams = new URLSearchParams(window.location.search)
    this.subMerchantUUid = queryParams.get("uuid");
    this.subMerchantIamport = queryParams.get("key");
    this.emailAddress = queryParams.get("email");
    if(Object.keys(this.$store.state.paymentChannels.channels).length < 1){
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`,{
        country: [],
        paymentMethod: "",
        merchant_uuids:this.subMerchantUUid
      });
    }
    var qrcode_channelName = queryParams.get("channelName")
   this.$store.dispatch(`paymentChannels/${FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS}`, {
      channelName: qrcode_channelName,
      payType: "PAYIN",
      merchant_uuids:this.subMerchantUUid
    });
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
  },

  watch: {
    paymentChannelCredsList() {
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
      this.paymentChannelCredsList.forEach((channel) => {
      if(channel.is_enabled){
          this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS_COUNT}`, {channel_key: channel.key, merchant_uuids:this.subMerchantUUid})
      }
    });

    },
    methosDetailsCountData() {
      this.methosDetailsCoun = cloneDeep(this.methosDetailsCountData);
    },
    paymentmethodCredsList() {
      this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    },
    selectedCountry() {
      this.selected_country = this.selectedCountry
    },
    selected_country() {
      // var queryParams = new URLSearchParams(window.location.search)
      // var qrcode_channelName = queryParams.get("channelName")
      var selected_country_for_method = ""
      if(this.paymentChannelCredsList.length > 0 && this.selected_country.length > 0){
        this.paymentChannelCredsList.forEach((channel) => {
          if(channel.country == this.selected_country){
             selected_country_for_method = channel.key
          }
        });
        this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method, merchant_uuids:this.subMerchantUUid})
      }
    },
    selected_country_for_add_cred(value) {
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country_for_add_cred.key){
            this.addChannelCred = cloneDeep(channel);
            this.addChannelCredCopy = cloneDeep(channel);
        }
      });

      if(value.key === 'HK' && this.topBarContData.key == 'GBPRIMEPAY') {
        if(this.showAddCredPayInmodal) {
          this.addChannelCred.creds['currency'] = 'HKD';
        }
      }
    },
    dataForInstallmentType() {
      this.installmentTypeData = cloneDeep(this.dataForInstallmentType);
    },
    iamportextraCreds(){
        this.iamportextracreds = cloneDeep(this.iamportextraCreds);
        this.iamportcreds = cloneDeep(this.iamportextraCreds);
        this.iamportcredscopy = cloneDeep(this.iamportextraCreds);

    },
    iamportextraUpdateCreds(){
      this.iamportupdatecreds = cloneDeep(this.iamportextraUpdateCreds)
    }
  },

  mounted() {
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    this.selected_country = this.countryList[0];
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
    this.selected_country = {}
    this.$store.commit(`paymentChannels/${UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE}`, []);
    this.$store.commit(`paymentChannels/${UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE}`, []);
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,

    changePaymentLink(method,cardType){
      let title;
      let enabled = true;
      let confirmBtnText = "common.enable";
      if(!method.is_default && (cardType == "card" || cardType === "subscription" || cardType === "subscription_bank_transfer" || cardType === "subscription_direct_debit")) {
        title = "Are you sure you want to make " + `${method.name}` + " as default " + `${method.type}` + "?";
        enabled = true;
      } else if(method.is_enabled) {
        title = "Are you sure you want to disable " + `${method.name}` + "?"
        enabled = false;
        confirmBtnText = "common.disable";
      } else {
        title = "Are you sure you want to enable " + `${method.name}` + "?";
        enabled = true;
      }

      Swal.fire({
        title: title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t(confirmBtnText),
        cancelButtonText: this.$t("common.cancel"),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        let data = {
          "action_type": "UPDATE_ENABLE_STATUS",
          "method_key": method.key,
          "method_sub_type": "",
          "is_enabled": method.is_enabled ? false : true
        };
        if(cardType == "card"){
          data = {
            "action_type": "UPDATE_DEFAULT_STATUS",
            "method_key": method.key,
            "method_sub_type": method.sub_type,
            "is_enabled": method.is_default ? false : true
          }
        }
        else if(cardType === "subscription" || cardType === "subscription_bank_transfer"  || cardType === "subscription_direct_debit") {
          let methodType = "";
          switch (cardType) {
            case 'subscription':
              methodType = 'SUBSCRIPTION_CARD'
              break;

            case 'subscription_bank_transfer':
              methodType = 'SUBSCRIPTION_BANK_TRANSFER'
              break;
          
            default:
              methodType = method.type;
              break;
          }

          data = {
            "action_type": "UPDATE_DEFAULT_SUBSCRIPTION_STATUS",
            "method_key": method.key,
            "method_type": methodType,
            "method_sub_type": method.sub_type,
            "is_enabled": method.is_default ? false : true
          }
        }

        if (result.value) {
          let selected_country_for_method = ""
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country === this.selected_country){
              selected_country_for_method = channel.key
            }
          })
          this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
            statusData: data,
            countryForMethod: selected_country_for_method,
            merchant_uuids:this.subMerchantUUid
          })
            .then(() => {
              if(enabled) {
                this.$notify({
                  type: "success",
                  text: `${method.name} enabled Successfully.`,
                  closeOnClick: true,
                });
              } else {
                this.$notify({
                type: "success",
                  text: `${method.name} disabled Successfully.`,
                  closeOnClick: true,
                });
              }
            })
            .catch((error) => {
              // error
              const err = JSON.parse(error.message);
              this.$notify({
                type: "error",
                text: err.message,
                closeOnClick: true,
              });
            });
        }
      });
    },
    changeCollectPayslip(method) {
      const data = {
        "action_type": "UPDATE_COLLECT_PAYSLIP",
        "method_key": method.key,
        "method_sub_type": method.sub_type,
        "is_enabled": method.collect_payslip ? false : true
      }

      let selected_country_for_method = "";
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country){
          selected_country_for_method = channel.key
        }
      })

      this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
        statusData: data,
        countryForMethod: selected_country_for_method,
        merchant_uuids: this.subMerchantUUid
      })
        .then((response) => {
          console.log(response);
          let message = method.collect_payslip ? "Collect Payslip has been disabled." : "Collect Payslip has been enabled.";

          let title = method.collect_payslip ? "Disabled!" : "Enabled!";
          Swal.fire(title, message, "success");
        })
        .catch((error) => {
          // error
          console.log("error", error);
          Swal.fire("Failed!", `Failed To ${method.collect_payslip ? "disable" : "enable"} payslip collection. Please retry`, "error");
        });
    },

    handlePayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      // handle separate case for each payment channel
      // if (this.$v.channelCreds[pckey].$invalid) { // data validation requied
      // if(!this.currentChannel) {
      //   return;
      // } else {
      if(!this.channelIsIamPort()){
        for (var key in this.currentChannel.creds) {
          if(typeof this.currentChannel.creds[key] !== 'boolean' && this.currentChannel.creds[key].includes("*") && this.currentChannel.creds[key][0] == "*") {
            delete this.currentChannelCopy.creds[key];
          } else {
            if(this.currentChannel.key === 'SHOPIFY' && key === 'shopUrl') {
              if(!this.isShopifyStoreUrl(this.currentChannel.creds[key])) {
                this.$notify({
                  type: "error",
                  text: `Please Enter valid Shopify store url`,
                  closeOnClick: true,
                });
                return false;
              }
            } else if(this.currentChannel.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat', 'mid-Cards'].includes(key)) {
              if(!this.currentChannel.creds[key]) {
                this.currentChannel.creds[key] = 'NA';
              }
            }
            this.currentChannelCopy.creds[key] = this.currentChannel.creds[key]
          }
        }
      }
      else{
        for (let key in this.currentChannel.creds) {
          if(typeof this.currentChannel.creds[key] !== 'boolean' && this.currentChannel.creds[key].includes("*") && this.currentChannel.creds[key][0] == "*") {
            delete this.currentChannelCopy.creds[key];
          }
          else{
          this.currentChannelCopy.creds[key] = this.currentChannel.creds[key]
          }
        }
        if(this.iamportextraupdatecreds !== null){
          const updatedIamportCredsForUpdate = Object.keys(this.iamportextraupdatecreds).reduce((acc, key) => {
            switch (key) {
              
              case 'eximbay':
                acc['eximbayMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'html5_inicis':
                acc['kginicisMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'kakaopay':
                acc['kakaopayMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'kcp':
                acc['kcpMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'naverpay':
                acc['naverpayMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'nice':
                acc['niceMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'payco':
                acc['paycoMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'paymentwall':
                acc['paymentwallMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'tosspay':
                acc['tosspayMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'uplus':
                acc['tossMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'tosspayments':
                acc['tosspaymentsMid'] = this.iamportextraupdatecreds[key].current;
                break;
              case 'nice_v2':
                acc['nicev2Mid'] = this.iamportextraupdatecreds[key].current;
                break;
              default:
                acc[key] = this.iamportextraupdatecreds[key].current;
                break;
            }
            return acc;
          }, {});
          
          for (let key in updatedIamportCredsForUpdate) {
            if(typeof updatedIamportCredsForUpdate[key] !== 'boolean' && updatedIamportCredsForUpdate[key].includes("*") && updatedIamportCredsForUpdate[key][0] == "*") {
                delete updatedIamportCredsForUpdate[key];
            }
            else{
            this.currentChannelCopy.creds[key] = updatedIamportCredsForUpdate[key];
            this.currentChannel.creds[key] = updatedIamportCredsForUpdate[key];
            }

          }
        }
      }
      this.$store.dispatch(`paymentChannels/${UPDATE_PAYMENT_METHOD_CREDENTIALS}`, {
        channelId: this.currentChannel.key,
        payChannel: this.currentChannelCopy,
        payType:"PAYIN",
        merchant_uuids:this.subMerchantUUid
      }).then(() => {
          var selected_country_for_method = ""
          if(this.paymentChannelCredsList.length > 0 && this.selected_country.length > 0){
            this.paymentChannelCredsList.forEach((channel) => {
              if(channel.country == this.selected_country){
                selected_country_for_method = channel.key
              }
            });
            this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method, merchant_uuids:this.subMerchantUUid})
          }
      })
      .catch((error) => {
        // error
        console.log("error", error);
      });
      
      //}
      this.hidePayInModal();
    },
    handlenext(){
      this.formvalue = 1;
      ///this.setpage = 1;
    },
    onClickingNext(setpage1){
      console.log(setpage1.formPage)
      this.formvalue = setpage1.formPage,
      this.formdata = setpage1.formdata
    },
    setpage(page){
      console.log(page)
      this.formvalue = page;

    },
    showextracreds(apiKey, secretKey){
      const reqBody = {
        "imp_key": apiKey,
        "imp_secret": secretKey,
      };
     this.showextracontent = true;
     this.$store.dispatch(`paymentChannels/${ADD_PAYMENT_METHOD_IMPORT_CREDENTIALS}`, reqBody).then((response) => {
                this.iamportextracreds = response;
                this.$notify({
                    type: "success",
                    text: "MIDs fetched successfully.",
                    closeOnClick: true,
                });
                
            })
            .catch((error) => {
            // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: this.$store.state.notification.message,
                    closeOnClick: true,
                });
            })
    },
    updateCreds() {
      this.$store.dispatch(`paymentChannels/${FETCH_PREVIEW_IAMPORT_CREDENTIALS}`).then((response) => {
        this.iamportextraupdatecreds = response;
        //this.inViewMode = true;
        this.expandIamportUpdateForm = true;
        this.updatedCredsLoaded = true;
        this.$notify({
          type: "success",
          text: "MIDs fetched successfully.",
          closeOnClick: true,
        });
      }).catch((error) => {
      // error
        console.log("error", error);
        this.$notify({
            type: "error",
            text: this.$store.state.notification.message,
            closeOnClick: true,
        });
      })
    },
    async showextracredsforupdate(){
      let payChannel = this.currentChannelCopy;
      let isChanged = false;
      let creds = {}
      for(let key in this.currentChannel.creds) {
        if((!payChannel.creds[key] || this.currentChannel.creds[key] !== payChannel.creds[key]) && !this.currentChannel.creds[key].startsWith('*')) {
          creds[key] = this.currentChannel.creds[key];
          isChanged = true;
        }
      }

      payChannel.creds = creds;

      if(isChanged) {
        this.$store.dispatch(`paymentChannels/${UPDATE_PAYMENT_METHOD_CREDENTIALS}`, {
          channelId: this.currentChannel.key,
          payChannel: payChannel,
          payType:"PAYIN",
          merchant_uuids:this.subMerchantUUid
        }).then(() => {
            var selected_country_for_method = ""
            if(this.paymentChannelCredsList.length > 0 && this.selected_country.length > 0){
              this.paymentChannelCredsList.forEach((channel) => {
                if(channel.country == this.selected_country){
                  selected_country_for_method = channel.key
                }
              });
              this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method, merchant_uuids:this.subMerchantUUid})
            }
            this.updateCreds();
        })
        .catch((error) => {
          // error
          console.log("error", error);
        });
      } else {
        this.updateCreds();
      }
    },


    handleAddCredPayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      // handle separate case for each payment channel
      // if (this.$v.channelCreds[pckey].$invalid) { // data validation requied
      // if(!this.currentChannel) {
      //   return;
      // } else {
      if(!this.channelIsIamPort()){
        for (var key in this.addChannelCred.creds) {
          if(typeof this.addChannelCred.creds[key] !== 'boolean' && this.addChannelCred.creds[key].includes("*") && this.addChannelCred.creds[key][0] == "*") {
            delete this.addChannelCredCopy.creds[key];
          } else {
            if(this.topBarContData.key === 'SHOPIFY' && key === 'shopUrl') {
              if(!this.isShopifyStoreUrl(this.addChannelCred.creds[key])) {
                this.$notify({
                  type: "error",
                  text: `Please Enter valid Shopify shop url`,
                  closeOnClick: true,
                });
                return false;
              }
            } else if(this.topBarContData.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat', 'mid-Cards'].includes(key)) {
              if(!this.addChannelCred.creds[key]) {
                this.addChannelCred.creds[key] = 'NA';
              }
            }
            this.addChannelCredCopy.creds[key] = this.addChannelCred.creds[key]
          }
        }
      }
      else{
        for (let key in this.addChannelCred.creds) {
          if(typeof this.addChannelCred.creds[key] !== 'boolean' && this.addChannelCred.creds[key].includes("*") && this.addChannelCred.creds[key][0] == "*") {
            delete this.addChannelCredCopy.creds[key];
          } 
        }
       
       this.iamportcreds.apiKey = this.paymentChannelCreds[0].creds.apiKey;
       this.iamportcreds.apiSecret = this.paymentChannelCreds[0].creds.apiSecret;
       this.iamportcreds.merchantId = this.paymentChannelCreds[0].creds.merchantId;


       const updatedIamportExtraCreds = Object.keys(this.iamportextracreds).reduce((acc, key) => {
          switch (key) {
            case 'eximbay':
              acc['eximbayMid'] = this.iamportextracreds[key];
              break;
            case 'html5_inicis':
              acc['kginicisMid'] = this.iamportextracreds[key];
              break;
            case 'kakaopay':
              acc['kakaopayMid'] = this.iamportextracreds[key];
              break;
            case 'kcp':
              acc['kcpMid'] = this.iamportextracreds[key];
              break;
            case 'naverpay':
              acc['naverpayMid'] = this.iamportextracreds[key];
              break;
            case 'nice':
              acc['niceMid'] = this.iamportextracreds[key];
              break;
            case 'payco':
              acc['paycoMid'] = this.iamportextracreds[key];
              break;
            case 'paymentwall':
              acc['paymentwallMid'] = this.iamportextracreds[key];
              break;
            case 'tosspay':
              acc['tosspayMid'] = this.iamportextracreds[key];
              break;
            case 'uplus':
              acc['tossMid'] = this.iamportextracreds[key];
              break;
            case 'tosspayments':
              acc['tosspaymentsMid'] = this.iamportextracreds[key];
              break;
            case 'nice_v2':
              acc['nicev2Mid'] = this.iamportextracreds[key];
              break;
            default:
              acc[key] = this.iamportextracreds[key];
              break;
          }
          return acc;
        }, {});


       const updatedIamportCreds = Object.keys(this.iamportcreds).reduce((acc, key) => {
          switch (key) {
            case 'apiKey':
              acc['apiKey'] = this.iamportcreds[key];
              break;
            case 'apiSecret':
              acc['apiSecret'] = this.iamportcreds[key];
              break;
            case 'merchantId':
              acc['merchantId'] = this.iamportcreds[key];
              break;
            case 'eximbay':
              acc['eximbayMid'] = this.iamportcreds[key];
              break;
            case 'html5_inicis':
              acc['kginicisMid'] = this.iamportcreds[key];
              break;
            case 'kakaopay':
              acc['kakaopayMid'] = this.iamportcreds[key];
              break;
            case 'kcp':
              acc['kcpMid'] = this.iamportcreds[key];
              break;
            case 'naverpay':
              acc['naverpayMid'] = this.iamportcreds[key];
              break;
            case 'nice':
              acc['niceMid'] = this.iamportcreds[key];
              break;
            case 'payco':
              acc['paycoMid'] = this.iamportcreds[key];
              break;
            case 'paymentwall':
              acc['paymentwallMid'] = this.iamportcreds[key];
              break;
            case 'tosspay':
              acc['tosspayMid'] = this.iamportcreds[key];
              break;
            case 'uplus':
              acc['tossMid'] = this.iamportcreds[key];
              break;
            case 'tosspayments':
              acc['tosspaymentsMid'] = this.iamportcreds[key];
              break;
            case 'nice_v2':
              acc['nicev2Mid'] = this.iamportcreds[key];
              break;
            default:
              acc[key] = this.iamportcreds[key];
              break;
          }
          return acc;
        }, {});

        for (let key in updatedIamportExtraCreds) {
          if ((Array.isArray(updatedIamportExtraCreds[key]) && updatedIamportExtraCreds[key].length === 0) || updatedIamportCreds[key] === undefined) {
          updatedIamportCreds[key] = "NA"
          }
        }
        
        this.addChannelCred.creds = updatedIamportCreds
        this.addChannelCredCopy.creds = this.addChannelCred.creds

      }

      // this is for omise
      if(this.addChannelCredCopy && this.addChannelCredCopy.creds && ('areOtherCurrenciesSupported' in this.addChannelCredCopy.creds) && this.addChannelCredCopy.creds.areOtherCurrenciesSupported == "") {
        this.addChannelCredCopy.creds.areOtherCurrenciesSupported = "false";
      }

      this.$store.dispatch(`paymentChannels/${ADD_PAYMENT_METHOD_CREDENTIALS}`, {
        channelId: this.addChannelCred.key,
        payChannel: this.addChannelCredCopy,
        payType:"PAYIN",
        merchant_uuids:this.subMerchantUUid
      }).then(() => {
        let selected_country_for_method = ""
        if(this.paymentChannelCredsList.length > 0){
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country === this.addChannelCredCopy.country){
              selected_country_for_method = channel.key
            }
          });
          this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method, merchant_uuids: this.subMerchantUUid})
          .then(() => {
            if(this.paymentmethodCredsList.length === 1) {
              let method = this.paymentmethodCredsList[0];
              let data = {
                "action_type": "UPDATE_ENABLE_STATUS",
                "method_key": method.key,
                "method_sub_type": "",
                "is_enabled": true
              };
              this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
                statusData: data,
                countryForMethod: selected_country_for_method,
                merchant_uuids:this.subMerchantUUid
              })
            }
          });
        }
      })
      .finally(this.hideAddCredPayInModal);
      //};
      //}
      // this.hideAddCredPayInModal();
    },

    markasEnabledAndDefault(method,cardType) {
      if(cardType == "card"){
        var data = {
            "action_type": "UPDATE_DEFAULT_STATUS",
            "method_key": method.key,
            "method_sub_type": method.sub_type,
            "is_enabled": method.is_default ? false : true
        }
      }else{
        data = {
            "action_type": "UPDATE_ENABLE_STATUS",
            "method_key": method.key,
            "method_sub_type": "",
            "is_enabled": method.is_enabled ? false : true
        }
      }
      var selected_country_for_method = ""
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country){
           selected_country_for_method = channel.key
        }
      });
      this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
        statusData: data,
        countryForMethod: selected_country_for_method,
        merchant_uuids:this.subMerchantUUid
      });
    },

    openPayInModal(channel,viewType) {
      this.showPayInmodal = true;
      this.currentChannel = cloneDeep(channel);
      this.currentChannelCopy = cloneDeep(channel);
      if(viewType == "preview" || channel.key.toLowerCase() == 'paypal'){
        this.inViewMode = true;
      }else{
        this.inViewMode = false;
      }
    },

    hidePayInModal() {
      this.currentChannel = null;
      this.currentChannelCopy = null;
      this.showPayInmodal = false;
      this.iamportextraupdatecreds = null;
      this.updatedCredsLoaded = false;
      this.expandIamportUpdateForm = false;
    },
    getChaipayWebhook() {
      let key = this.chaiPayKey;
      if(this.isMasterMerchant && this.$route.query.key) {
        key = this.$route.query.key;
      }
      return `${CHAIPAY_API_URL}/webhook/${this.$route.query.channelName.toLowerCase()}/${key}`;
    },

    openAddCredPayInModal() {
      if(this.$route.query.channelName === 'PORTONE_KR') {
        this.selected_country_for_add_cred = "GLOBAL";
        this.addChannelCred = this.paymentChannelCredsList.find((channel) => channel.country === "GLOBAL");
        this.addChannelCredCopy = this.addChannelCred;
        this.showAddCredPayInmodal = true;
      }else if(this.$route.query.channelName === 'KLASHA') {
        this.selected_country_for_add_cred = "KE";
        this.addChannelCred = this.paymentChannelCredsList.find((channel) => channel.country === "KE");
        this.addChannelCredCopy = this.addChannelCred;
        this.showAddCredPayInmodal = true;
      } else if(this.countryListForAddCred.length > 0) {
          this.selected_country_for_add_cred = this.countryListForAddCred[0];
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country == this.countryListForAddCred[0].key){
              this.addChannelCred = cloneDeep(channel);
              this.addChannelCredCopy = cloneDeep(channel);
            }
          });
          this.showAddCredPayInmodal = true;
      }else{
        this.NoCountryAvailable();
      }
    },
    openAddOnboardingInModal() { 
      if(this.countryListForAddCred.length > 0){
          this.selected_country_for_add_cred = this.countryListForAddCred[0];
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country == this.countryListForAddCred[0].key){
              this.addChannelCred = cloneDeep(channel);
              this.addChannelCredCopy = cloneDeep(channel);
            }
          });
          this.showAddOnboardingInmodal = true;
          //this.formvalue = 0;
      }else{
        this.status = "Pending";
        this.showAddOnboardingInmodal = true;
        //this.NoCountryAvailable();
      }
    },
    getPaymentMethod(channel){
      this.selected_country = channel.country;
      // var selected_country_for_method = channel.key
      // this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method})
    },

    hideAddCredPayInModal() {
      this.addChannelCred = null;
      this.addChannelCredCopy = null;
      this.showAddCredPayInmodal = false;
      this.iamportextracreds = null;
    },
    hideAddOnboardingInModal() {
      this.addChannelCred = null;
      this.addChannelCredCopy = null;
      this.showAddOnboardingInmodal = false;
    },

    onCopyWebhookURL() {
      var queryParams = new URLSearchParams(window.location.search);
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${queryParams.get("channelName").toLowerCase()} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    NoCountryAvailable() {
      this.$notify({
        type: "info",
        text: this.$t('views.payment_channels.no_more_countries_to_add'),
        closeOnClick: true,
      });
    },
    credentialFieldFormat(key){
      const text = key;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;

    },
    convertCodeToCountry(val){
      if(val == "GLOBAL"){
        return "GLOBAL";
      } else {
        return new Intl.DisplayNames(['en'], {type: 'region'}).of(val);
      }
    },
    hideAddAndUpdateKeys() {
      return !['paypal', 'stripe'].includes(this.$route.query.channelName.toLowerCase());
    },
    channelIsIamPort() {
      return this.$route.query.channelName === 'PORTONE_KR';
    },
    channelIsKlasha() {
      return this.$route.query.channelName === 'KLASHA';
    },
    instalmentMonths(items) {
      return items.map((item) => {
        return { text: `${item}M`, value: item }
      })
    },
    updateInstalmentData(data) {
      let selectedCountryForMethod;
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country){
          selectedCountryForMethod = channel.key
        }
      });

      this.$store.dispatch(`paymentChannels/${UPDATE_INSTALMENT_TYPE_DATA}`, {
        reqBody: {
          action_type: "UPDATE_INFO",
          method_key: data.key,
          method_sub_type: data.sub_type,
          info: { term_list: this.installmentTypeData[0].info.term_list }
        },
        channelName: selectedCountryForMethod,
        merchant_uuids:this.subMerchantUUid
      }).then(response => {
        console.log(response);
      });
    },
    updateMerchantSponsor(data, value) {
      if(data.is_merchant_sponsored){
        console.log(data);
        Swal.fire({
          title: "Are you sure you want to disable merchant sponsored "+ `${data.name}`+ "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("common.disable"),
          cancelButtonText: this.$t("common.cancel"),
          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
          },
        }).then(result => {
          
        
        let selectedCountryForMethod;
        this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country){
          selectedCountryForMethod = channel.key
        }
        });
          if (result.value) {
            this.$store.dispatch(`paymentChannels/${UPDATE_INSTALMENT_TYPE_DATA}`, {
                reqBody: {
                  action_type: "UPDATE_MERCHANT_SPONSPRED_STATUS",
                  method_key: data.key,
                  method_sub_type: data.sub_type,
                  is_enabled: value
                },
                channelName: selectedCountryForMethod,
                merchant_uuids:this.subMerchantUUid
              }).then(response => {
                console.log(response);
                this.$notify({
                  type: "success",
                  text: `${data.name}`+ " disabled Successfully.",
                  closeOnClick: true,
                });
                data.is_merchant_sponsored = value;
              
                Swal.fire("Disabled!", `${data.name}`+ " has been disabled.", "success");
              })
              .catch((error) => {
                // error
                console.log("error", error);
                Swal.fire("Deleted!", "Failed To disable " + `${data.name}`+".", "error");
              });
          }
        });
      }
      else{
        Swal.fire({
          title: "Are you sure you want to enable merchant sponsored " + `${data.name}`+ "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("common.enable"),
          cancelButtonText: this.$t("common.cancel"),
          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
          },
        }).then(result => {
          
        let selectedCountryForMethod;
        this.paymentChannelCredsList.forEach((channel) => {
          if(channel.country == this.selected_country){
            selectedCountryForMethod = channel.key
          }
        })
          if (result.value) {
            this.$store.dispatch(`paymentChannels/${UPDATE_INSTALMENT_TYPE_DATA}`, {
                reqBody: {
                  action_type: "UPDATE_MERCHANT_SPONSPRED_STATUS",
                  method_key: data.key,
                  method_sub_type: data.sub_type,
                  is_enabled: value
                },
                channelName: selectedCountryForMethod,
                merchant_uuids:this.subMerchantUUid
              }).then(response => {
                console.log(response);
                this.$notify({
                  type: "success",
                  text: `${data.name}`+ " enabled Successfully.",
                  closeOnClick: true,
                });
                data.is_merchant_sponsored = value;
              
                Swal.fire("Enabled!", `${data.name}`+ " has been enabled.", "success");
              })
              .catch((error) => {
                // error
                console.log("error", error);
                Swal.fire("Sorry!", "Failed To enable " + `${data.name}`+ ".", "error");
              });
          }
        });

      }
      // let selectedCountryForMethod;
      // this.paymentChannelCredsList.forEach((channel) => {
      //   if(channel.country == this.selected_country){
      //     selectedCountryForMethod = channel.key
      //   }
      // });

      // this.$store.dispatch(`paymentChannels/${UPDATE_INSTALMENT_TYPE_DATA}`, {
      //   reqBody: {
      //     action_type: "UPDATE_MERCHANT_SPONSPRED_STATUS",
      //     method_key: data.key,
      //     method_sub_type: data.sub_type,
      //     is_enabled: value
      //   },
      //   channelName: selectedCountryForMethod
      // }).then(response => {
      //   console.log(response);
      //   data.is_merchant_sponsored = value;
      // });
    },
    isShopifyStoreUrl(userInput) {
      return userInput.match(/(https:\/\/.)[^.\s]+\.myshopify\.com/);
    },
    paymentChannel(key) {
      if(key && key.toLowerCase() === "paypal") {
        return 'PayPal';
      }
      if(key === "PORTONE_KR") {
        return "PORTONE Korea";
      }
      if(key === "PAYLETTER"){
        return "PAYLETTER(GLOBAL)";
      }
      if(key === "PAYLETTER_KR"){
        return "PAYLETTER(KR)";
      }
      return key;
    },
    gobackChannel() {
      if(this.$route.query.isFromRecon == 'true'){
        this.$router.push({name: 'Payment Channels', query: {tab: 'Recon Channels'}});
      }else{
        this.$router.push("/settings/pay-channel");
      }
    }
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="">
      <div class="pt-0">
          <!-- Payment Channel tab -->
            <div class="row">
              <div v-if="emailAddress" style="font-weight: bold;font-size: 18px;" class="col-12 mb-3">{{emailAddress}}</div>
              <div class="col-xl-5 col-sm-12">
                <div
                  class="card"
                  style="background: rgba(220, 218, 213, 0.30);border-radius: 15px;border: 1px #DCDAD5 solid; box-shadow: none; margin-bottom: 0px;">
                  <div class="card-body" style="padding: 30px;">
                      <div class="right-top-section">
                        <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                        <div class="">
                          <div class="media-body media-body-custom img-container">
                            <img
                              :src="`${topBarContData.logo}`"
                              alt
                              class="avatar-custom"
                              style="object-fit: contain; display: block;"
                            />
                            <div>
                              <p style="margin-bottom: 0px;font-size: 20px;font-weight: 400;padding-left: 25px;">
                                {{ paymentChannel(topBarContData.key) }}
                              </p>
                              <p style="margin-bottom: 0px;margin-top: 10px;font-size: 16px;font-weight: 325;padding-left: 25px; color:rgba(0, 0, 0, 0.50);">
                                {{ topBarContData.type }}
                              </p>
                            </div>
                          </div>
                          <div style="color: rgba(0, 0, 0, 0.70); font-size: 14px; word-wrap: break-word; padding-top: 10px;line-height: normal;">
                            {{ topBarContData.description }}
                          </div>
                          <div class="button-section">
                            <b-btn class="back-button" @click="gobackChannel">
                              <img src="@/assets/images/arrow_left.svg" alt="back" />
                            </b-btn>
                            <button class="btn btn-custom btn-primary dark-bg" v-if="channelIsIamPort() && this.countryListForAddCred.length === 0" @click="openPayInModal(paymentChannelCredsList.find(channel => channel.key === 'PORTONE_KR_GLOBAL'),'preview')">{{ $t('views.payment_channels.manage_keys') }}</button>
                            <button class="btn btn-custom btn-primary dark-bg" v-if="channelIsKlasha() && this.countryListForAddCred.length === 0" @click="openPayInModal(paymentChannelCredsList.find(channel => channel.key === 'KLASHA_KE'),'preview')">{{ $t('views.payment_channels.manage_keys') }}</button>
                            <b-button id="add_keys" v-if="hideAddAndUpdateKeys() && !this.channelIsIamPort() && !this.channelIsKlasha()" @click="openAddCredPayInModal()" class="btn btn-custom btn-primary dark-bg">+ {{$t('views.payment_channels.add_keys')}} </b-button>
                            <b-button id="add_keys" v-else-if="this.countryListForAddCred.length !== 0 && this.channelIsIamPort()" @click="openAddCredPayInModal()" class="btn btn-custom btn-primary dark-bg">+ {{$t('views.payment_channels.add_keys')}} </b-button>
                            <b-button id="add_keys" v-else-if="this.countryListForAddCred.length !== 0 && this.channelIsKlasha()" @click="openAddCredPayInModal()" class="btn btn-custom btn-primary btn-block dark-bg">+ {{$t('views.payment_channels.add_keys')}} </b-button>
                            <div v-if="topBarContData.key == 'GBPRIMEPAY' && topBarContData.onboarding_status.status == 'NOT_STARTED' && !isMasterMerchant ">
                              <div v-if="this.$store.state.auth.environment === 'live'">
                                <b-button id="add_keys1"   class="btn btn-custom btn-primary dark-bg" :href="kyc_url+'/'+ this.$store.state.auth.currentUser.iamportKey" target="_blank" >Activation Form  </b-button>
                              </div>
                              <div v-else>
                                <b-button id="add_keys1"   class="btn btn-custom btn-primary dark-bg" :href="kyc_url+'/'+ this.$store.state.auth.currentUser.iamportKey + '?environment=' + this.$store.state.auth.environment" target="_blank" >Activation Form  </b-button>
                              </div>
                            </div>
                            <div v-else-if="topBarContData.key == 'GBPRIMEPAY' && topBarContData.onboarding_status.status == 'NOT_STARTED' && isMasterMerchant ">
                              <div v-if="this.$store.state.auth.environment === 'live'">
                                <b-button id="add_keys1"   class="btn btn-custom btn-primary dark-bg" :href="kyc_url+'/'+ this.subMerchantIamport" target="_blank" >Activation Form  </b-button>
                              </div>
                              <div v-else>
                                <b-button id="add_keys1"   class="btn btn-custom btn-primary dark-bg" :href="kyc_url+'/'+ this.subMerchantIamport + '?environment=' + this.$store.state.auth.environment" target="_blank" >Activation Form  </b-button>
                              </div>
                            </div>
                            <!-- @click="openAddOnboardingInModal()"  @click="openAddOnboardingInModal()"-->
                            <div v-else-if="topBarContData.key == 'GBPRIMEPAY' && topBarContData.onboarding_status.status == 'Pending' && !isMasterMerchant">
                              <div v-if="this.$store.state.auth.environment === 'live'">
                                <b-button id="add_keys2"   class="btn btn-custom btn-primary dark-bg"  :href="kyc_url+'/'+ this.$store.state.auth.currentUser.iamportKey" target="_blank">Activation Status  </b-button>
                              </div>
                              <div v-else>
                                <b-button id="add_keys2"   class="btn btn-custom btn-primary dark-bg"  :href="kyc_url+'/'+ this.$store.state.auth.currentUser.iamportKey + '?environment=' + this.$store.state.auth.environment" target="_blank">Activation Status  </b-button>
                              </div>
                            </div>
                            <div v-else-if="topBarContData.key == 'GBPRIMEPAY' && topBarContData.onboarding_status.status == 'Pending' && isMasterMerchant">
                              <div v-if="this.$store.state.auth.environment === 'live'">
                                <b-button id="add_keys2"   class="btn btn-custom btn-primary dark-bg"  :href="kyc_url+'/'+ this.subMerchantIamport" target="_blank">Activation Status  </b-button>
                              </div>
                              <div v-else>
                                <b-button id="add_keys2"   class="btn btn-custom btn-primary dark-bg"  :href="kyc_url+'/'+ this.subMerchantIamport + '?environment=' + this.$store.state.auth.environment" target="_blank">Activation Status  </b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="right-bottom-section" style="margin-top: 25px; margin-bottom: 25px;">
                  <div class=""> 
                    <div
                      v-for="(data, index) in paymentChannelCredsList"
                      :key="index">
                      <div class="cred-section mt-3" v-if="data.is_enabled">
                        <div class="creds-container"
                        :class="{
                            'active': selected_country == data.country
                          }">
                            <div class="creds-container-top-section">
                              <div class="img-container cursor-pointer" @click="getPaymentMethod(data)">
                                <div style="width: 40px;height: 40px;margin-right: 20px;">
                                  <img v-if="data.country == 'GLOBAL'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/global.png" alt />
                                  <img v-if="data.country == 'VN'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/vietnam_rounded.png" alt />
                                  <img v-if="data.country == 'TH'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/thailand.jpeg" alt />
                                  <img v-if="data.country == 'ID'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/indonesia.png" alt />
                                  <img v-if="data.country == 'SG'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/singapore.png" alt />
                                  <img v-if="data.country == 'MY'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/malaysia.svg" alt />
                                  <img v-if="data.country == 'TW'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/taiwan.png" alt />
                                  <img v-if="data.country == 'PH'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/philippines.png" alt />
                                  <img v-if="data.country == 'HK'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/hong_kong.svg" alt />
                                  <img v-if="data.country == 'JP'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/japan.png" alt />
                                  <img v-if="data.country == 'IN'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/india.svg" alt />
                                  <img v-if="data.country == 'AU'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/australia.svg" alt />
                                  <img v-if="data.country == 'KR'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/korea.svg" alt />
                                  <img v-if="data.country == 'KE'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/Kenya.png" alt />
                                  <img v-if="data.country == 'ZA'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/south_africa.png" alt />
                                  <img v-if="data.country == 'NG'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/nigeria.png" alt />
                                  <!-- <img v-if="data.country == 'MY'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/malaysia.svg" alt /> -->
                                </div>
                                <div>
                                  <div style="font-size: 18px;font-weight: 400;" class="cursor-pointer value-class d-inline-block country-class">{{convertCodeToCountry(data.country)}}</div>
                                  <div style="color: rgba(0, 0, 0, 0.50);font-size: 14px;font-weight: 325;padding-top: 10px;">Select {{convertCodeToCountry(data.country)}} payments </div>
                                </div>
                              </div>
                              <!-- <button class="btn btn-primary float-right btn-sm"  ></button> -->
                              <span v-if="!channelIsIamPort() && !channelIsKlasha()" @click="openPayInModal(data,'preview')" role="button" tabindex="0" style="float: right; color: #FC6B2D; cursor: pointer;">{{ $t('views.payment_channels.manage_keys') }}
                                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <g id="Iconsax/Linear/arrowright">
                                    <path id="Vector" d="M10.8225 4.44727L15.375 8.99977L10.8225 13.5523M2.625 8.99977H15.2475" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                            <!-- <div @click="getPaymentMethod(data)" class="cursor-pointer creds-container-bottom-section">
                              <div class="payment_type_text">
                                <span>{{ $t('views.payment_channels.payment_type.wallet') }}</span>
                                <span id="paymentMethodToolTipEnabled" v-if="methosDetailsCountData[data.key]" class="payment_type_enabled_count">{{methosDetailsCountData[data.key].walletEnabled}}</span>
                                <b-tooltip target="paymentMethodToolTipEnabled">
                                  <p>
                                    {{
                                      "Enabled Count"
                                    }}
                                  </p>
                                </b-tooltip>
                                <span id="paymentMethodToolTipTotal" v-if="methosDetailsCountData[data.key]" class="payment_type_count">{{methosDetailsCountData[data.key].wallet}}</span>
                                <b-tooltip target="paymentMethodToolTipTotal">
                                  <p>
                                    {{
                                      "Total"
                                    }}
                                  </p>
                                </b-tooltip>
                              </div>
                              <div class="payment_type_text">
                                <span>{{ $t('views.payment_channels.payment_type.credit_cards') }}</span>
                                <span id="paymentMethodToolTipEnabled1" v-if="methosDetailsCountData[data.key]" class="payment_type_enabled_count">{{methosDetailsCountData[data.key].cardEnabled}}</span>
                                <b-tooltip target="paymentMethodToolTipEnabled1">
                                  <p>
                                    {{
                                      "Enabled Count"
                                    }}
                                  </p>
                                </b-tooltip>
                                <span id="paymentMethodToolTipTotal1" v-if="methosDetailsCountData[data.key]" class="payment_type_count">{{methosDetailsCountData[data.key].card}}</span>
                                <b-tooltip target="paymentMethodToolTipTotal1">
                                  <p>
                                    {{
                                      "Total"
                                    }}
                                  </p>
                                </b-tooltip>
                              </div>
                              <div class="payment_type_text">
                                <span>{{ $t('views.payment_channels.payment_type.atm_cards') }}</span>
                                <span id="paymentMethodToolTipEnabled2" v-if="methosDetailsCountData[data.key]" class="payment_type_enabled_count">{{methosDetailsCountData[data.key].atmCardEnabled}}</span>
                                <b-tooltip target="paymentMethodToolTipEnabled2">
                                  <p>
                                    {{
                                      "Enabled Count"
                                    }}
                                  </p>
                                </b-tooltip>
                                <span id="paymentMethodToolTipTotal2" v-if="methosDetailsCountData[data.key]" class="payment_type_count">{{methosDetailsCountData[data.key].atmCard}}</span>
                                <b-tooltip target="paymentMethodToolTipTotal2">
                                  <p>
                                    {{
                                      "Total"
                                    }}
                                  </p>
                                </b-tooltip>
                              </div>
                              <div class="payment_type_text">
                                <span>{{ $t('views.payment_channels.payment_type.all') }}</span>
                                <span id="paymentMethodToolTipEnabled3" v-if="methosDetailsCountData[data.key]" class="payment_type_enabled_count">{{methosDetailsCountData[data.key].allEnabled}}</span>
                                <b-tooltip target="paymentMethodToolTipEnabled3">
                                  <p>
                                    {{
                                      "Enabled Count"
                                    }}
                                  </p>
                                </b-tooltip>
                                <span id="paymentMethodToolTipTotal3" v-if="methosDetailsCountData[data.key]" class="payment_type_count">{{methosDetailsCountData[data.key].all}}</span>
                                <b-tooltip target="paymentMethodToolTipTotal3">
                                  <p>
                                    {{
                                      "Total"
                                    }}
                                  </p>
                                </b-tooltip>
                              </div>
                            </div>    -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-7 col-sm-12">
                  <div
                  v-if="dataForWalletType.length > 0 || dataForBnplType.length > 0 || dataForPayByBankType.length > 0 || dataForUPIType.length > 0 || dataForEMIType.length > 0 || dataForNetBankingType.length > 0  ||  dataForQRCodeType.length > 0 || dataForCardType.length > 0 || dataForSmartButtonType.length > 0 || dataForCreditDebitCardType.length > 0 || dataForUnionPayCardType.length > 0 || installmentTypeData.length > 0 || dataForAtmCardType.length > 0 || dataForDebitCardType.length > 0 || dataForAllType.length > 0 || dataForCodType.length > 0 || dataForDirectBankTransferType.length > 0  || dataForBankTransferType.length > 0 || dataForCryptoType.length > 0 || dataForOTCType.length > 0 || dataForVaBankTransferType.length > 0 || dataForDirectDebitType.length || dataForSubscriptionType.length > 0"
                  class="card"
                  style="box-shadow: none; border-radius: 15px; border: 1px #DCDAD5 solid; padding-bottom: 25px;">
                    <div style="padding: 0px 0px 15px 0px;border-bottom: 1px solid rgb(220, 218, 213);margin: 25px 24px 0px 25px;" class="card-body card-body-custom">
                      <div class="methods-heading">{{convertCodeToCountry(selected_country)}}</div>
                    </div>
                    <div v-if="dataForWalletType.length > 0" class="card-body card-body-custom" style="padding: 0px 25px;">
                      <div v-b-toggle.collapse-wallet class="county-wallet-top-bar d-flex justify-content-between custom-collapse" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <div>
                          <img src="@/assets/images/wallet.png" alt />
                          <p class="d-inline">{{ $t('views.payment_channels.payment_type.wallets') }}</p>
                        </div>
                        <img class="arrow_img" :class="{'rotate_img': showCollapse.wallet}" src="@/assets/images/bottom_arrow.png" alt="down"/>
                      </div>
                      <b-collapse v-model="showCollapse.wallet" id="collapse-wallet">
                        <div
                          v-for="(data, index) in dataForWalletType"
                          :key="index"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">
                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div v-if="dataForBnplType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div v-b-toggle.collapse-bnpl class="county-wallet-top-bar d-flex justify-content-between custom-collapse" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <div>
                          <img src="@/assets/images/wallet.png" alt />
                          <p class="d-inline">BNPL</p>
                         </div>
                         <img class="arrow_img" :class="{'rotate_img': showCollapse.bnpl}" src="@/assets/images/bottom_arrow.png" alt="down"/>
                      </div>
                      <b-collapse v-model="showCollapse.bnpl" id="collapse-bnpl">
                        <div
                          v-for="(data, index) in dataForBnplType"
                          :key="index"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">
                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div v-if="dataForNetBankingType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div v-b-toggle.collapse-netBanking class="county-wallet-top-bar d-flex justify-content-between custom-collapse" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <div>
                          <img src="@/assets/images/net_banking.png" alt />
                          <p class="d-inline">Net Banking</p>
                        </div>
                        <img class="arrow_img" :class="{'rotate_img': showCollapse.netBanking}" src="@/assets/images/bottom_arrow.png" alt="down"/>
                      </div>
                      <b-collapse v-model="showCollapse.netBanking" id="collapse-netBanking">
                        <div
                          v-for="(data, index) in dataForNetBankingType"
                          :key="index"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">
                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div v-if="dataForEMIType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/emi.svg" alt />
                        <p class="d-inline">EMI</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForEMIType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                          <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div v-if="dataForQRCodeType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div v-b-toggle.collapse-qrCode class="county-wallet-top-bar d-flex justify-content-between custom-collapse" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <div>
                          <img src="@/assets/images/qrcode.svg" alt />
                          <p class="d-inline">QR Code</p>
                        </div>
                        <img class="arrow_img" :class="{'rotate_img': showCollapse.qrCode}" src="@/assets/images/bottom_arrow.png" alt="down"/>
                      </div>
                       <b-collapse v-model="showCollapse.qrCode" id="collapse-qrCode">
                        <div
                          v-for="(data, index) in dataForQRCodeType"
                          :key="index"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                            </div>
                          </div>
                        </div>
                       </b-collapse>
                    </div>

                    <div v-if="dataForCreditDebitCardType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline"> Credit/Debit Cards </p>
                      </div>
                      <div
                        v-for="(data) in dataForCreditDebitCardType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                          <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForUnionPayCardType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline"> UnionPay Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForUnionPayCardType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                          <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForCardType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline">{{ $t('views.payment_channels.payment_type.credit_cards') }}</p>
                      </div>
                      <div
                        v-for="(data) in dataForCardType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForSmartButtonType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/smartButton.svg" alt />
                        <p class="d-inline">Smart Button</p>
                      </div>
                      <div
                        v-for="(data) in dataForSmartButtonType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="dataForAtmCardType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline">{{ $t('views.payment_channels.payment_type.atm_cards') }}</p>
                      </div>
                      <div
                        v-for="(data) in dataForAtmCardType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class=""> 
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="dataForDebitCardType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline">Debit Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForDebitCardType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 26px;height: 26px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="dataForCryptoType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/crypto.png" alt />
                        <p class="d-inline">{{ $t('views.payment_channels.payment_type.crypto') }}</p>
                      </div>
                      <div
                        v-for="(data) in dataForCryptoType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                           <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="installmentTypeData.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div v-b-toggle.collapse-installment class="county-wallet-top-bar d-flex justify-content-between custom-collapse" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <div>
                          <img src="@/assets/images/card.png" alt />
                          <p class="d-inline">Installment</p>
                        </div>
                        <img class="arrow_img" :class="{'rotate_img': showCollapse.installment}" src="@/assets/images/bottom_arrow.png" alt="down"/>
                      </div>
                      <b-collapse v-model="showCollapse.installment" id="collapse-installment">
                        <div
                          v-for="(data, index) in installmentTypeData"
                          :key="data.key"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">

                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                              <checkbox-btn :isEnabled="data.is_merchant_sponsored" merchantSponsor @btn-clicked="updateMerchantSponsor(data, !data.is_merchant_sponsored)" />
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                            </div>
                          </div>
                          <div class="row my-4" style="padding-left:25px;" v-for="(term, key) in data.info.term_list" :key="key">
                            <div class="col-md-4">
                              {{term.bank_name}}
                            </div>
                            <div class="col-md-8">
                              <!-- <span v-for="(validTerm, index) in term.valid_terms" :key="index">
                                <button
                                  href="javascript:void(0);"
                                  class="btn btn-primary px-1 py-1 m-1"
                                  v-if="term.selected_terms.includes(validTerm)"
                                  style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff;"
                                >
                                  <div class="checkbox-group">
                                    <label class="filled position-relative mb-0" v-bind:for="index">{{ validTerm }}M</label>
                                  </div>
                                </button>
                                <button
                                  href="javascript:void(0);"
                                  class="btn btn-primary px-1 py-1 m-1"
                                  v-else
                                  style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff;"
                                >
                                  <div class="checkbox-group">
                                    <label class="position-relative mb-0" v-bind:for="index">{{ validTerm }}M</label>
                                  </div>
                                </button>
                              </span> -->
                              <b-form-checkbox-group
                                v-model="installmentTypeData[index].info.term_list[key].selected_terms"
                                :options="instalmentMonths(term.valid_terms)"
                                @change="updateInstalmentData(data)"
                              ></b-form-checkbox-group>

                              <!-- {{term.selected_terms}}
                              {{term.valid_terms}} -->
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div v-if="dataForBankTransferType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/bank_transfer.png" alt />
                        <p class="d-inline">{{ $t('views.payment_channels.payment_type.bank_transfer') }}</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForBankTransferType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                           <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="dataForCodType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div v-for="(data, index) in dataForCodType" :key="index">
                        <div class="county-wallet-top-bar" style="display: flex;justify-content: space-between;align-items: center; background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                          <div>
                            <img src="@/assets/images/cash.svg" alt />
                            <p class="d-inline">{{ $t('views.payment_channels.payment_type.cod') }}</p>
                          </div>
                          <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                        </div>
                        <!-- <div
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                            <div class=""> -->
                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              
                            <!-- </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div v-if="dataForOTCType.length > 0" style="padding: 0px 25px;"  class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/otc.png" alt />
                        <p class="d-inline">Over The Counter</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForOTCType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                           <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForPayByBankType.length > 0" style="padding: 0px 25px;"  class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/net_banking.png" alt />
                        <p class="d-inline">Pay By Bank</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForPayByBankType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                           <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="dataForUPIType.length > 0"  style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/upi.svg" alt />
                        <p class="d-inline">UPI</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForUPIType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'card')" />
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>



                    <div v-if="dataForDirectBankTransferType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div  v-for="(data, index) in dataForDirectBankTransferType" :key="index">
                        <div class="county-wallet-top-bar" style="display: flex;justify-content: space-between;align-items: center; background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                          <div>
                            <img src="@/assets/images/bank_transfer.png" alt />
                            <p class="d-inline">Direct Bank Transfer</p>
                          </div>
                          <div>
                             <checkbox-btn :isEnabled="data.collect_payslip" collectPayslip @btn-clicked="changeCollectPayslip(data)" />
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                        <!-- <div
                          v-for="(data, index) in dataForDirectBankTransferType" :key="index"
                          class="cred-section mt-4">
                          <div class="row">
                            <div class="col-md-4 col-6 col-sm-5" style="color: #505d69;"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                            <div class="col-md-8 col-6 col-sm-7"> -->

                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                              <checkbox-btn :isEnabled="data.collect_payslip" collectPayslip @btn-clicked="changeCollectPayslip(data)" /> -->

                            <!-- </div>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div v-if="dataForDirectDebitType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline">Direct Debit</p>
                      </div>
                      <div
                        v-for="(data) in dataForDirectDebitType"
                        :key="data.key"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">

                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForVaBankTransferType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/bank_transfer.png" alt />
                        <p class="d-inline">VA Bank Transfer</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForVaBankTransferType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div v-if="dataForAllType.length > 0" style="padding: 0px 25px;" class="card-body card-body-custom">
                      <div class="county-wallet-top-bar"  style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <i class="ri-money-dollar-box-fill" style="color: black;font-size: 25px;display: inline-block; vertical-align: middle;line-height: 0;"></i>
                        <p class="d-inline">{{ $t('views.payment_channels.payment_type.all') }}</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForAllType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                            <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dataForSubscriptionType.length > 0" style="padding: 20px 25px;" class="card-body card-body-custom">
                      <div class="methods-heading">Subscription</div>
                      <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline">Credit Cards</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForSubscriptionType"
                        :key="index"
                        class="cred-section">
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                          <div class="">
                            <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                             <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'subscription')" />
                             <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                          </div>
                        </div>
                      </div>
                      <div v-if="dataForSubscriptionBankTransferType.length > 0" class="">
                        <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                          <img src="@/assets/images/bank_transfer.png" alt />
                          <p class="d-inline">Bank Transfer</p>
                        </div>
                        <div
                          v-for="(data, index) in dataForSubscriptionBankTransferType"
                          :key="index"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class="">

                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'subscription_bank_transfer')" />
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="dataForSubscriptionDirectDebitType.length > 0" class="">
                        <div class="county-wallet-top-bar" style="background: rgba(220, 218, 213, 0.30);border-radius: 8px;margin-top: 10px;padding: 20px 25px;">
                          <img src="@/assets/images/card.png" alt />
                          <p class="d-inline">Direct Debit</p>
                        </div>
                        <div
                          v-for="(data) in dataForSubscriptionDirectDebitType"
                          :key="data.key"
                          class="cred-section">
                          <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 25px; border-bottom: 1px #DCDAD5 solid">
                            <div class="" style="color: #000; font-size:14px;">
                              <img style="width: 30px;height: 30px;margin-right: 15px;" :src="`${data.logo}`" alt="">
                              <span class="d-inline-block align-middle">{{ data.name }}</span>
                            </div>
                            <div class=""> 

                              <!-- <checkbox-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" /> -->
                              <checkbox-btn :isEnabled="data.is_default" defaultType @btn-clicked="changePaymentLink(data, 'subscription_direct_debit')" />
                              <toggle-btn :isEnabled="data.is_enabled" @btn-clicked="changePaymentLink(data)" />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-xl-12 col-sm-12">
                <div class="card"
                  style="background: rgba(220, 218, 213, 0.3); border-radius: 15px; box-shadow: none;" v-if="hideAddAndUpdateKeys()">
                  <div class="card-body" style="padding: 30px;">
                      <form id="modal" action="#">
                        <div class="form-group" style="margin-bottom: 0px;">
                          <label style="" for="webhookUrl">{{ $t('views.payment_channels.webhook_endpoint') }}</label>
                          <span data-v-06c6b9d8="" :title="$t('common.copy')"
                            v-b-tooltip.hover
                            v-clipboard:copy="getChaipayWebhook()"
                            v-clipboard:success="onCopyWebhookURL"
                            class="ml-2 cursor-pointer copy-icon" style="cursor: pointer; width: 39px; height: 39px; background: rgb(245, 244, 243); border-radius: 8px; display: inline-block;">
                            <svg data-v-06c6b9d8="" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                            <g data-v-06c6b9d8="" opacity="0.7" clip-path="url(#clip0_1540_47398)">
                              <path data-v-06c6b9d8="" d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path data-v-06c6b9d8="" d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                            <defs data-v-06c6b9d8="">
                              <clipPath data-v-06c6b9d8="" id="clip0_1540_47398">
                                <rect data-v-06c6b9d8="" width="18" height="18" fill="white"></rect>
                              </clipPath>
                            </defs>
                            </svg>
                          </span>


                          <input
                            id="webhookUrl"
                            type="text"
                            class="form-control"
                            name="webhookUrl"
                            v-clipboard:copy="getChaipayWebhook()"
                            :value="getChaipayWebhook()"
                            readonly
                          />
                          <b-tooltip target="webhookUrl">
                            <p>
                              {{ $t('views.payouts.config_tooltip') }}
                            </p>
                          </b-tooltip>
                        </div>
                      </form>
                  </div>
                </div>
              </div>
              <!-- <div
                class="col-xl-3 col-sm-6"
                v-for="(data, index) in paymentChannelCredsList"
                :key="index"
              >
                <div
                  class="card border"
                  :class="{
                    'border-success': data.is_enabled,
                    'border-primary': !data.is_enabled,
                  }"
                >
                  <div class="card-body card-body-custom">
                    <div class="">
                      <img
                        :src="`${data.logo}`"
                        alt
                        class="avatar-lg"
                        style="object-fit: contain;,display: inline-block;"
                      />
                      <div class="media-body media-body-custom">
                        <p class="text-muted mb-0">
                          <i
                            v-if="data.type == 'Wallet'"
                            class="ri-wallet-3-fill mr-1 align-middle"
                          ></i>
                          <i
                            v-if="data.type == 'Gateway'"
                            class="ri-bank-card-2-fill mr-1 align-middle"
                          ></i>
                          <i
                            v-if="data.type == 'Aggregator'"
                            class="ri-filter-2-fill mr-1 align-middle"
                          ></i>
                          {{ data.type }}
                        </p>
                        <p class="text-muted mb-0 mt-2">
                          {{ data.key }}
                        </p>
                      </div>
                    </div>

                    <button
                      href="javascript:void(0);"
                      class="btn btn-primary btn-block"
                      v-if="!data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.setup") }}
                    </button>
                    <button
                      href="javascript:void(0);"
                      class="btn btn-success btn-block"
                      v-if="data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.edit") }}
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
      </div>

      <!-- Payment Channel Modal -->
      <!-- <div v-if="currentChannel">
        <b-modal
          id="modal-2"
          centered
          v-model="showPayInmodal"
          :title="currentChannel.key"
          title-class="text-dark font-18"
          hide-footer
        >
          <form id="modal" action="#" @submit.prevent="handlePayInSubmit">
            <div
              class="form-group"
              v-for="(value, key) in currentChannel.creds"
              :key="key"
            >
              <label :for="key">{{ key }}</label>
              <input
                :id="key"
                v-model="currentChannel.creds[key]"
                type="text"
                class="form-control"
                :placeholder="`Enter ${key}`"
                :name="key"
              />
            </div>

            <div class="form-group">
              <label for="webhookUrl">{{
                "Chaipay server webhook endpoint"
              }}</label>
              <input
                id="webhookUrl"
                type="text"
                class="form-control"
                name="webhookUrl"
                v-clipboard:copy="getChaipayWebhook()"
                :value="getChaipayWebhook()"
                readonly
              />
              <b-tooltip target="webhookUrl">
                <p>
                  {{
                    "Configure this endpoint with payment provider as webhook endpoint"
                  }}
                </p>
              </b-tooltip>
            </div>

            <div class="text-right">
              <b-button class="mr-1" variant="light" @click="hidePayInModal"
                >Close</b-button
              >

              <button
                type="submit"
                class="btn btn-success ml-1"
                :disabled="!isValid"
              >
                Save
              </button>
            </div>
          </form>
        </b-modal>
      </div> -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showPayInmodal
          }">
          <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
          <div v-if="currentChannel" data-simplebar class="h-100">
            <div class="rightbar-title">
              <div class="right-top-section">
                <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                <div class="card-body-custom" style="padding: 25px;">
                  <i class="ri-pencil-fill cursor-pointer edit-button" v-if="inViewMode && hideAddAndUpdateKeys()" @click="openPayInModal(currentChannel,'edit')"></i>
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hidePayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarContData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0" style="font-size: 20px;font-weight: 400;margin-top: 20px;">
                      {{ paymentChannel(topBarContData.key) }} {{ topBarContData.type }}
                    </p>
                  </div>
                </div>
                <div class="border-sep"></div>
              </div>
            </div>
            <div style="padding: 25px;">
              <!-- Edit Channel Creds -->
              <form id="modal" action="#" @submit.prevent="handlePayInSubmit">
                <!-- show this when channel is not a PORTONE_KR -->
                <div class="form-group" v-if="!channelIsIamPort() && !channelIsKlasha()">
                  <label>Country</label>
                  <div class="form-control">{{convertCodeToCountry(currentChannel.country)}}</div>
                </div>

                <!-- channel creds in view and edit mode for PORTONE_KR -->
                <div v-if="channelIsIamPort()">
                  <div
                    class="form-group"
                    v-for="(value, key) in currentChannel.creds"
                    :key="key"
                  >
                    <!-- channel creds in edit mode -->
                    <div v-if="!inViewMode">
                      <span v-if="key.startsWith('BE_CONFIG_')"></span>
                      <div v-else-if=" key === 'apiKey' || key === 'apiSecret' || key === 'merchantId' " >
                        <label :for="key">
                          {{ credentialFieldFormat(key) }}
                        </label>
                        <input
                          :id="key"
                          v-model="currentChannel.creds[key]"
                          type="text"
                          class="form-control"
                          :placeholder="`Enter ${key}`"
                          :name="key"
                          :readonly="updatedCredsLoaded"
                        />
                      </div>
                    </div>
                    <!-- channel creds in view only -->
                    <div v-else>
                      <label :for="key" class="d-flex">
                        {{ credentialFieldFormat(key) }}
                      </label>
                      <input
                        :id="key"
                        v-model="currentChannel.creds[key]"
                        type="text"
                        class="form-control"
                        :placeholder="`Enter ${key}`"
                        :name="key"
                        :readonly="inViewMode"
                      />
                    </div>
                  </div>

                  <div v-if="!inViewMode && !expandIamportUpdateForm" class="text-left">
                    <div v-if="currentChannel.creds['apiKey'] && currentChannel.creds['apiSecret'] && currentChannel.creds['merchantId']">
                      <b-btn @click="showextracredsforupdate()"
                        class="dark-bg">
                        Update MIDs
                      </b-btn>
                    </div>
                    <div v-else>
                      <span 
                        class="btn btn-success grey-bg">
                        Update MIDs
                      </span>
                    </div>
                  </div>
                  <br/>
                  <!-- PORTONE_KR Update MIDs -->
                  <div v-if="expandIamportUpdateForm === true">
                    <div
                      class="form-group row mb-3"
                      v-for="(value, key) in iamportextraupdatecreds"
                      :key="key"
                    >
                      <div class="col-3 my-auto" v-if="!inViewMode">
                        <label class="my-auto" v-if="key === 'html5_inicis'">
                          Kginicis
                        </label>
                        <label class="my-auto" v-else-if="key === 'uplus'">
                          Toss (uplus)
                        </label>
                        <label class="my-auto" v-else-if="key === 'tosspayments'">
                          Toss Payments
                        </label>
                        <label class="my-auto" v-else-if="key === 'nice_v2'">
                          Nice v2
                        </label>
                        <label class="my-auto" v-else>
                        {{ key.replace('_', ' ')
                                  .toLowerCase()
                                  .replace(/\b\w/g, (c) => c.toUpperCase())
                                  .replace(/\s/g, '') }}</label>
                      </div>
                      <div class='col-9'>
                        <multiselect
                          v-if="!inViewMode"
                          v-model="value.current"
                          :options="value.available"
                          placeholder="Select default MID"
                          class="cursor-pointer"
                          :searchable="true"
                          :multiple="false"
                          :allowEmpty="false"
                          :show-labels="false"
                          :typeable="false"
                          :taggable="false"
                        ></multiselect>
                      </div>
                    </div>
                  </div>

                  <!-- show buttons in Edit mode and show for PORTONE_KR After MIDs fetched -->
                  <div v-if="!inViewMode && (!channelIsIamPort() || expandIamportUpdateForm)" class="text-left">
                    <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                    <b-button class="mr-2" style="padding: 12px 20px; font-weight: 400; color:#000; background:#fff;border: none;" variant="light" @click="hidePayInModal"
                      >Cancel</b-button>
                    <button
                      type="submit"
                      class="btn"
                      :disabled="!isValid"
                      style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                    >
                      Save
                    </button>
                  </div>

                </div>
                <!-- channel creds in view and edit mode -->
                <div v-else>
                  <div
                    class="form-group"
                    v-for="(value, key) in currentChannel.creds"
                    :key="key"
                  >
                    <!-- channel creds view and edit mode for GBPRIMEPAY -->
                    <div v-if="key == 'currency' &&  topBarContData.key == 'GBPRIMEPAY'">
                      <span v-if="currentChannel.country === 'HK'"></span>
                      <span v-else>
                        <!-- View this message in edit mode -->
                        <label v-if="!inViewMode" class="error">Please make sure the currency matches your GBPP account configuration</label>
                        <label>Currency</label>
                        <!-- view this in view mode -->
                        <label v-if="inViewMode" class="form-control">{{ currentChannel.creds[key] }}</label>
                        <!-- view this in edit mode -->
                        <multiselect
                          v-else-if="!inViewMode"
                          v-model="currentChannel.creds[key]"
                          :options="['THB', 'USD']"
                          placeholder="Select Currency"
                          size="sm"
                          class="cursor-pointer"
                          :searchable="true"
                          :multiple="false"
                          :taggable="true"
                        ></multiselect>
                      </span>
                    </div>
                    <!-- channel creds edit mode for ASIAPAY -->
                    <div v-else-if="key == 'asiapayEntity' && topBarContData.key == 'ASIAPAY' && !inViewMode">
                      <label>{{ credentialFieldFormat(key) }}</label>
                      <multiselect
                        v-model="currentChannel.creds[key]"
                        :options="['PesoPay', 'PayDollar']"
                        placeholder="Select Entity"
                        size="sm"
                        class="cursor-pointer"
                        :searchable="true"
                        :multiple="false"
                        :allowEmpty="false"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                    <span v-else-if="key.startsWith('BE_CONFIG_')"></span>
                    <div v-else-if="['areOtherCurrenciesSupported', 'shouldAuthenticate'].includes(key)">
                      <b-form-checkbox
                        v-model="currentChannel.creds[key]"
                        value="true"
                        unchecked-value="false"
                        v-if="(key !== 'areOtherCurrenciesSupported') || (key === 'areOtherCurrenciesSupported' && currentChannel.country !== 'MY')"
                        :disabled="inViewMode"
                      >
                        {{ credentialFieldFormat(key) }}
                      </b-form-checkbox>
                    </div>
                    <!-- channel creds view mode for ALL OTHER PSP -->
                    <div v-else>
                      <label :for="key">
                        {{ key == 'paypalAttrbutionID' ? 'PayPalAttrbutionID' : credentialFieldFormat(key) }} 
                        {{ currentChannel.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat'].includes(key) ? '(Optional)' : '' }}
                      </label>
                      <input
                        :id="key"
                        v-model="currentChannel.creds[key]"
                        type="text"
                        class="form-control"
                        :placeholder="`Enter ${key}`"
                        :name="key"
                        :readonly="inViewMode"
                      />
                    </div>
                  </div>
                  <!-- show this message in edit mode for KBANK -->
                  <p v-if="!inViewMode && currentChannel.key === 'KBANK'" class="error">
                    *If MID-Cards not specified, Default MCC MID will be used <br />
                    *To enable Alipay, Installments, and WeChat Add MIDs
                  </p>
                  <!-- show this buttons in edit mode -->
                  <div v-if="!inViewMode" class="text-left">
                    <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                    <b-button style="padding: 12px 20px; font-weight: 400; color:#000; background:#fff;border: none;" variant="light" class="mr-2" @click="hidePayInModal"
                      >Cancel</b-button>
                    <button
                      type="submit"
                      class="btn"
                      :disabled="!isValid"
                      style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hidePayInModal" class="rightbar-overlay"
        :class="{
            'active': showPayInmodal
        }"></div>
      </div>
      <!-- Payment Channel Modal For Add Cred -->
      <!-- <div v-if="addChannelCred">
        <b-modal
          id="modal-2"
          centered
          v-model="showAddCredPayInmodal"
          :title="addChannelCred.key"
          title-class="text-dark font-18"
          hide-footer
        >
          <form id="modal" action="#" @submit.prevent="handleAddCredPayInSubmit">
            <multiselect
              v-model="selected_country_for_add_cred"
              :options="countryListForAddCred"
              placeholder="Select Country"
              size="sm"
              :searchable="false"
              :multiple="false"
              :taggable="true"
              :allowEmpty="false"
              :show-labels="false"
            ></multiselect>
            <div
              class="form-group"
              v-for="(value, key) in addChannelCred.creds"
              :key="key"
            >
              <label :for="key">{{ key }}</label>
              <input
                :id="key"
                v-model="addChannelCred.creds[key]"
                type="text"
                class="form-control"
                :placeholder="`Enter ${key}`"
                :name="key"
              />
            </div>
            <div class="form-group">
              <label for="webhookUrl">{{

                "Chaipay server webhook endpoint"
              }}</label>
              <input
                id="webhookUrl"
                type="text"
                class="form-control"
                name="webhookUrl"
                v-clipboard:copy="getChaipayWebhookAddCred()"
                :value="getChaipayWebhookAddCred()"
                readonly
              />
              <b-tooltip target="webhookUrl">
                <p>
                  {{
                    "Configure this endpoint with payment provider as webhook endpoint"
                  }}
                </p>
              </b-tooltip>
            </div>

            <div class="text-right">
              <b-button class="mr-1" variant="light" @click="hideAddCredPayInModal"
                >Close</b-button
              >

              <button
                type="submit"
                class="btn btn-success ml-1"
                :disabled="!isValidAddCred"
              >
                Save
              </button>
            </div>
          </form>
        </b-modal>
      </div> -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showAddCredPayInmodal
          }">
          <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
          <div v-if="addChannelCred" data-simplebar class="h-100">
            <div class="rightbar-title">
              <div class="right-top-section">
                <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                <div class="card-body-custom" style="padding: 25px;">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideAddCredPayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarContData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0" style="font-size: 20px;font-weight: 400;margin-top: 20px;">
                      {{ paymentChannel(topBarContData.key) }} {{ topBarContData.type }}
                    </p>
                  </div>
                </div>
                <div class="border-sep"></div>
              </div>
            </div>
            <div style="padding: 25px;" class="rightbar-content">
              <form id="modal1" action="#" @submit.prevent="handleAddCredPayInSubmit">
                <div v-if="topBarContData.onboarding_status.status === 'NOT_STARTED' && topBarContData.key === 'PORTONE_KR'">

                  <div
                  class="form-group"
                  v-for="(value, key) in paymentChannelCreds[0].creds"
                  :key="key"
                  >
                    <div v-if="key === 'apiKey' || key === 'apiSecret' || key === 'merchantId'">
                      <input
                        :id="key"
                        v-model="paymentChannelCreds[0].creds[key]"
                        type="text"
                        class="form-control"
                        :placeholder="`Enter ${key}`"
                        :name="key"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>

                  <multiselect
                    v-if="!channelIsIamPort() && !channelIsKlasha()"
                    v-model="selected_country_for_add_cred"
                    :options="countryListForAddCred"
                    placeholder="Select Country"
                    track-by="value"
                    label="value"
                    size="sm"
                    class="cursor-pointer"
                    :searchable="false"
                    :multiple="false"
                    :taggable="true"
                    :allowEmpty="false"
                    :show-labels="false"
                    style="margin-bottom: 25px;"
                  ></multiselect>
                  <div
                    class="form-group"
                    v-for="(value, key) in addChannelCred.creds"
                    :key="key"
                  >
                    <p v-if="key == 'shopUrl'" style="font-size: small;" class="mb-0 text-info">(Example url: https://examplestore.myshopify.com)</p>
                    <span v-if="key == 'currency' &&  topBarContData.key == 'GBPRIMEPAY'">
                      <span v-if="selected_country_for_add_cred.key === 'HK'"></span>
                      <span v-else>
                        <label class="error">Please make sure the currency matches your GBPP account configuration</label>
                        <multiselect
                          v-model="addChannelCred.creds[key]"
                          :options="['THB', 'USD']"
                          placeholder="Select Currency"
                          size="sm"
                          class="cursor-pointer"
                          :searchable="true"
                          :multiple="false"
                          :allowEmpty="false"
                          :show-labels="false"
                        ></multiselect>
                      </span>
                    </span>
                    <span v-else-if="key.startsWith('BE_CONFIG_')"></span>
                    <multiselect
                      v-else-if="key == 'asiapayEntity' && topBarContData.key == 'ASIAPAY'"
                      v-model="addChannelCred.creds[key]"
                      :options="['PesoPay', 'PayDollar']"
                      :placeholder="`Select ${credentialFieldFormat(key)}`"
                      size="sm"
                      class="cursor-pointer"
                      :searchable="true"
                      :multiple="false"
                      :allowEmpty="false"
                      :show-labels="false"
                    ></multiselect>

                    <div v-else-if="['areOtherCurrenciesSupported', 'shouldAuthenticate'].includes(key)">
                      <b-form-checkbox
                        v-model="addChannelCred.creds[key]"
                        value="true"
                        unchecked-value="false"
                        v-if="(key !== 'areOtherCurrenciesSupported') || (key === 'areOtherCurrenciesSupported' && selected_country_for_add_cred.key !== 'MY')"
                      >
                        {{ credentialFieldFormat(key) }}
                      </b-form-checkbox>
                    </div>
                    <input
                      :id="key"
                      v-else-if="topBarContData.key === 'KBANK' && ['mid-Alipay', 'mid-Installments', 'mid-Wechat'].includes(key)"
                      v-model="addChannelCred.creds[key]"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${credentialFieldFormat(key)} (Optional)`"
                      :name="key"
                    />
                    <input
                      :id="key"
                      v-else
                      v-model="addChannelCred.creds[key]"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${credentialFieldFormat(key)}`"
                      :name="key"
                    />
                  </div>

                  <p v-if="topBarContData.key === 'KBANK'" class="error">
                    *If MID-Cards not specified, Default MCC MID will be used <br />
                    *To enable Alipay, Installments and WeChat, Add MIDs
                  </p>
                </div>

                <div v-if="topBarContData.onboarding_status.status == 'NOT_STARTED' && topBarContData.key==='PORTONE_KR'" class="rightbar-content p-3">
                  <div class="text-left">

                    <div v-if="paymentChannelCreds[0].creds['apiKey'] && paymentChannelCreds[0].creds['apiSecret'] && paymentChannelCreds[0].creds['merchantId']">
                      <b-btn @click="showextracreds(paymentChannelCreds[0].creds['apiKey'], paymentChannelCreds[0].creds['apiSecret'])"
                        class="dark-bg">
                        Fetch MIDs
                      </b-btn>
                    </div>
                    <div v-else>
                      <b-btn class="grey-bg" disabled>
                        Fetch MIDs
                      </b-btn>
                    </div>
                  </div>
                </div>


                <div v-if="showextracontent === true && iamportextracreds !== null">
                  Payment Channels Settings
                  <br/>
                  <!-- <div>Default MID or SiteCode will be used if you don't specify it in the code, or you use Sgopify plugin.</div> -->
                  <br/>
                  <div class="form-group row mb-3" v-for="(value, key) in iamportextracreds"
                  :key="key"> 
                    <div class="col-3 my-auto">
                      <label class="my-auto" v-if="key === 'html5_inicis'">
                        Kginicis
                      </label>
                      <label class="my-auto" v-else-if="key === 'uplus'">
                        Toss (uplus)
                      </label>
                      <label class="my-auto" v-else-if="key === 'tosspayments'">
                        Toss Payments
                      </label>
                      <label class="my-auto" v-else-if="key === 'nice_v2'">
                        Nice v2
                      </label>
                      <label class="my-auto" v-else>
                          {{ key.replace('_', ' ')
                                .toLowerCase()
                                .replace(/\b\w/g, (c) => c.toUpperCase())
                                .replace(/\s/g, '') }} </label>
                    </div>
                    <div class="col-9">
                      <multiselect
                        v-if="topBarContData.key === 'PORTONE_KR'"
                        v-model="iamportcreds[key]"
                        :options="value"
                        placeholder="Select default MID"
                        class="cursor-pointer"
                        :searchable="true"
                        :multiple="false"
                        :allowEmpty="false"
                        :show-labels="false"
                        :typeable="false"
                        :taggable="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="text-center">
                    <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                    <button 
                    style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                    class="btn">
                      Save
                    </button>
                  </div>
                </div>
                <div class="text-left" v-else-if="!showextracontent && topBarContData.key !== 'PORTONE_KR'">
                  <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                  <button 
                  style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                  class="btn">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideAddCredPayInModal" class="rightbar-overlay"
        :class="{
            'active': showAddCredPayInmodal
        }"></div>
      </div>
      <div style="z-index: 100" >
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showAddOnboardingInmodal
          }">
          <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
          <div data-simplebar class="h-100">
            <div class="rightbar-title">
              <div class="right-top-section">
                <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                <div class="card-body-custom" style="padding: 25px;">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideAddOnboardingInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarContData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0" style="font-size: 20px;font-weight: 400;margin-top: 20px;">
                      {{ paymentChannel(topBarContData.key) }} {{ topBarContData.type }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="border-sep"></div>
            </div>
            <div style="padding: 25px;" class="rightbar-content">
              <div v-if="this.status == 'Pending' && topBarContData.onboarding_type_default == false">
                <label class="px-2" for="selectCountry">
                    Select Country
                  </label>
                  
                    <multiselect
                      v-model="selected"
                      :options="selected"
                      placeholder="Select Country"
                      track-by="value"
                      label="value"
                      size="sm"
                      class="cursor-pointer"
                      :searchable="false"
                      :multiple="false"
                      :taggable="true"
                      :allowEmpty="false"
                      :show-labels="false"
                    ></multiselect>

                    <label class="px-2" for="activationform">
                    Activation Form
                    </label>
                    <h6 class="px-2">Complete and submit the form to activate PSP</h6>
                    <div class="progressbar">
                      <div class="progresscontent">
                        <div class="item1" style="background-color: green; color: white" >Activation Process</div>
                        <div class="item1" style="background-color: green; color: white">Activation Form</div>
                        <div class="item1" style="background-color: green; color: white">Upload Documents</div>
                      </div>
                    </div>
                    <div class="col-md-12 rounded px-0 py-3 bg-white text-left" style="background: white; ">
                      <OnboardingForm :channelName="channelName" :formvalue="this.formvalue" :formDatas1="this.formdata" :status="this.status" v-on:onClickNext="onClickingNext($event)"  />
                    </div>
                    

              </div>
              <div v-else-if="topBarContData.onboarding_type_default == false && topBarContData.onboarding_status.status == 'NOT_STARTED'">
                <div v-if="this.formvalue !== 3">
                  <label class="px-2" for="selectCountry">
                    Select Country
                  </label>
                  
                    <multiselect
                      v-model="selected_country_for_add_cred"
                      :options="countryListForAddCred"
                      placeholder="Select Country"
                      track-by="value"
                      label="value"
                      size="sm"
                      class="cursor-pointer"
                      :searchable="false"
                      :multiple="false"
                      :taggable="true"
                      :allowEmpty="false"
                      :show-labels="false"
                    ></multiselect>

                    <label class="px-2" for="activationform">
                    Activation Form
                    </label>
                    <h6 class="px-2">Complete and submit the form to activate PSP</h6>
                </div>

                <!-- <div v-if="this.formvalue == 0"> 
                  <div class="horizontal-align three-items my-3">
                    <div class="item" style="background-color: black; color: white" >Activation Process</div>
                    <div class="item">Activation Form</div>
                    <div class="item">Upload Documents</div>
                  </div>

                  <div class="wrapper">
                  <ul class="StepProgress">
                    <li class="StepProgress-item"><strong>Activation Form information added</strong></li>
                    <li class="StepProgress-item"><strong>Document uploaded</strong>
                    </li>
                    <li class="StepProgress-item"><strong>PSP Approved</strong></li>
                    <li class="StepProgress-item"><strong>Live API credentials Shared</strong></li>
                    <li class="StepProgress-item"><strong>Keys Added</strong></li>
                    <li class="StepProgress-item"><strong>Gone Live</strong></li>
                  </ul>
                  </div>
                  
                  
                  <div class="text-left">
                    <button
                      @click="handlenext"
                      class="btn btn-success dark-bg mt-4 col-4"
                      
                    >
                      Next
                    </button>
                  </div>
                </div> -->
                <div v-if="this.formvalue == 0 || this.formvalue == 1 || this.formvalue == 2 || this.formvalue == 3"> 
                  <div v-if="this.formvalue == 0" class="horizontal-align three-items my-3">
                    <!-- <div class="horizontal-align three-items my-3">
                      <div class="item" style="background-color: black; color: white" >Activation Process</div>
                      <div class="item">Activation Form</div>
                      <div class="item">Upload Documents</div>
                    </div> -->
                    <div class="progressbar">
                      <div class="progresscontent">
                        <div class="item1" style="background-color: black; color: white" >Activation Process</div>
                        <div class="item1">Activation Form</div>
                        <div class="item1">Upload Documents</div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="this.formvalue == 1" class="horizontal-align three-items my-3">
                    <div class="progressbar">
                      <div class="progresscontent">
                        <div class="item1" style="background-color: green; color: white" @click=setpage(0) >Activation Process</div>
                        <div class="item1" style="background-color: black; color: white" >Activation Form</div>
                        <div class="item1">Upload Documents</div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="this.formvalue == 2" class="horizontal-align three-items my-3">
                    <div class="progressbar">
                      <div class="progresscontent">
                        <div class="item1" style="background-color: green; color: white" @click=setpage(0) >Activation Process</div>
                        <div class="item1" style="background-color: green; color: white" @click=setpage(1) >Activation Form</div>
                        <div class="item1" style="background-color: black; color: white">Upload Documents</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 rounded px-0 py-3 bg-white text-left" style="background: white; ">
                    <OnboardingForm :channelName="channelName" :formvalue="this.formvalue" :formDatas1="this.formdata" :status="this.status" v-on:onClickNext="onClickingNext($event)"  />
                  </div>
                  
                  
                  
                </div>
              </div>
              
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div class="rightbar-overlay"
        :class="{
            'active': showAddOnboardingInmodal
        }"></div>
      </div>

    </div>
  </Layout>
</template>

<style scoped>
::v-deep .form-check-label {
  font-weight: normal;
}
::v-deep .custom-control-label {
  font-weight: normal;
}
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
}
.media-body-custom{
    display: inline-block;
    vertical-align: middle;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.btn-custom{
    float: right;
    margin-top: 30px;
    background-color: #5664d2;
    border-color: #5664d2;
}

.creds-container{
  position: relative;
  border-radius: 15px; 
  border: 1px #DCDAD5 solid;
}
.creds-container.active{
  background: rgba(220, 218, 213, 0.30);
}
.creds-container-top-section{
  padding: 20px 25px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creds-container-bottom-section{
  padding: 10px;
  padding-top: 0px;
  border-top: 1px solid #D9D9D9;
}
.payment_type_text{
    font-size: 12px;
    width: auto;
    color: #BFBFBF;
    display: inline-block;
    margin-right: 25px;
    margin-top: 10px;
}
.payment_type_count{
    background: #E4FAF3;
    border-radius: 5px;
    padding: 2px 11px;
    margin-left: 5px;
    color: #16B079;
    font-size: 11px;
}
.edit-button{
  position: absolute;
  right: 50px;
  top: 25px;
  font-size: 15px;
}
.view-button{
  float: right;
  letter-spacing: 0px;
  color: #B3AFAF;
  font-size: 13px;
  text-decoration: underline;
}
.methods-heading{
  font-size: 20px;
  font-weight: 400;
}
.payment_type_enabled_count{
    background: #eaeaea;
    border-radius: 5px;
    padding: 2px 11px;
    margin-left: 5px;
    color: #505d69;
    font-size: 11px;
}
.vue-js-switch{
  margin-bottom: 0px;
}
.check-class{
    padding-right: 5px;
    position: relative;
    top: 3px;
    color:#1cbb8c;
    font-weight: bold;
}
.county-wallet-top-bar img{
    height:24px;
    width:24px;
    display:inline-block;
    vertical-align: middle;
}
.county-wallet-top-bar p{
    display:inline-block;
    vertical-align: middle;
    margin-left: 15px;
    font-size: 14px;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.right-top-section{
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 30%;
  right: -30%;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.cursor-pointer{
  cursor: pointer;
}
/* .right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414;;
} */
/* ::v-deep .custom-multiselect .multiselect__select:before{
  z-index: 1;
} */
.checkbox-group{
  display: inline-block;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group  label.filled:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.avatar-custom{
    height: 70px;
    width: 95px;
}
.copy-icon{
  position: absolute;
  right: 35px;
  top: 60px;
}
#webhookUrl{
    padding-right: 35px;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
  .make-default-class{
    margin-left: 0px !important;
    margin-top: 10px;
    display: block;
  }
}
@media (max-width: 1420px) {
  .right-bar{
    width: 40%;
    right: -40%;
  }
}
@media (max-width: 400px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.horizontal-align > * {
  font-size: 14px;
}

.horizontal-align.three-items .item {
  width: 33.3%;
}

.horizontal-align .item {
  display: inline-block;
  vertical-align: center;
  text-align: center;
  height: auto;
  min-height: 30px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: #eee;
}

/* .wrapper {
  width: 330px;
  font-family: 'Helvetica';
  font-size: 14px;
  border: 1px solid #CCC;
} */
.progressbar{
  width: min(95%, 45rem);
  margin: 0 auto;
  cursor: pointer;
}
.progresscontent{
  display: flex;

}
.item1{
  text-align: center;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  background: #eee;
}

/* ::v-deep .multiselect__placeholder {
  color: #75788d !important
} */
.grey-bg{
  background-color: grey;
  border-color: grey;
}

.img-container{
  display: flex;
  align-items: center;
  justify-content: left;
}
.right-top-section button{
  padding-left: 16px;
  padding-right: 16px;
  height: 44px;
  font-size: 14px;
 }
.button-section{
  border-top: 1px #DCDAD5 solid;
  margin-top: 30px;
}
.back-button{
    width: 44px;
    height: 44px;
    border-radius: 12px;
    padding: 0 !important;
    background: #dfdedc;
    border: none;
    margin-top:30px;
}

::v-deep .page-content label{
    color: black;
    font-size: 15px;
    margin-bottom: 10px;
}
.page-content .form-group{
    margin-bottom: 25px;
}
::v-deep .page-content select, ::v-deep .page-content textarea, ::v-deep .page-content .form-control {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.border-sep{
  border-top: 1px #DCDAD5 solid;
}
::v-deep .multiselect__tags {
  border-radius: 12px;
  padding: 12px 15px !important;
  height: 44px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single {
  font-size: 15px !important;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .multiselect__select{
  height: 44px;
}
::v-deep .multiselect__placeholder{
  color: #75788d !important;
}
.arrow_img.rotate_img{
  transform: rotate(180deg);
}
.county-wallet-top-bar .arrow_img{
  height: 18px;
  width: 18px;
  transition: .3s;
}

</style>